// NOTE: whenever you change these functions, also consider updating the corresponding functions in:
// packages/adquick-classic/src/marketplace/utils/QueryString.js
import { Controller } from "stimulus";
import StimulusReflex from "stimulus_reflex";
import QueryString from "query-string";

export default class extends Controller {
  async connect() {
    StimulusReflex.register(this);
    // FIX: This is not working as expected, had errors where `stimulate` was called before the connection was open.
    // It is occurring intermittently. Maybe we should revert this so other people don't assume it is working as it seems to be.
    await this.waitForConnection();
    this.connected();
  }

  connected() {
    // implement this function inside each controller and use instead of connect, so we know the required stimulus reflex setup has finished
  }

  waitForConnection() {
    return new Promise((resolve) => {
      const checkConnection = () => {
        if (this.isActionCableConnectionOpen()) {
          resolve();
        } else {
          setTimeout(checkConnection, 100); // Check again after 100ms
        }
      };
      checkConnection();
    });
  }

  stringifyQueryState(queryState) {
    return QueryString.stringify(queryState, { arrayFormat: "bracket" });
  }

  get params() {
    return QueryString.parse(window.location.search, { arrayFormat: "bracket", parseBooleans: true });
  }

  paramsAdd(key, val, dispatchEvent = true) {
    const state = { ...this.params };
    const stateForUrl = { ...state, [key]: val };
    history.replaceState(state, null, `?${this.stringifyQueryState(stateForUrl)}`);
    if (dispatchEvent) this.dispatchCustomEvent();
  }

  paramsRemove(key, dispatchEvent = true) {
    const state = { ...this.params };
    delete state[key];
    const stateForUrl = { ...state };
    history.replaceState(state, null, `?${this.stringifyQueryState(stateForUrl)}`);
    if (dispatchEvent) this.dispatchCustomEvent();
  }

  paramsRemoveAllStartingWith(startingWith, dispatchEvent = true) {
    const state = { ...this.params };
    const keysToRemove = Object.keys(state).filter(key => key.startsWith(startingWith));
    keysToRemove.forEach(key => delete state[key]);
    const stateForUrl = { ...state };
    history.replaceState(state, null, `?${this.stringifyQueryState(stateForUrl)}`);
    if (dispatchEvent) this.dispatchCustomEvent();
  }

  paramsAddToArray(key, value) {
    this.paramsAdd(
      key,
      []
        .concat(this.params[key])
        .concat(value)
        .filter(i => !!i),
    );
  }

  paramsRemoveFromArray(key, value) {
    const state = { ...this.params };
    const stateForUrl = { ...state, ...{ [key]: state[key].filter(v => v != value) } };
    history.replaceState(state, null, `?${this.stringifyQueryState(stateForUrl)}`);
    this.dispatchCustomEvent();
  }

  hasParam(key) {
    return Object.keys(this.params).includes(key);
  }

  hasArrayParam(key, value) {
    return this.hasParam(key) && this.params[key].includes(value);
  }

  paramsClear() {
    history.replaceState(null, null, location.pathname);
    this.dispatchCustomEvent();
  }

  dispatchCustomEvent() {
    window.dispatchEvent(new CustomEvent("adquick:history:replacestate", { detail: { params: this.params } }));
  }
}
