import ApplicationController from "../application_controller";
export default class extends ApplicationController {
  static targets = ["option", "input", "customAmount", "main"];

  connected() {
    StimulusReflex.register(this);
    this.populate_spend_levels();
  }

  selectRadioOption(e) {
    this.optionTargets.forEach((el, i) => {
      if (e.target.value == this.customAmountTarget.value) {
        return;
      }
      el.classList.toggle("active", e.currentTarget == el);
      if (e.currentTarget == el) {
        const valueToUpdate = el.attributes.for.value;
        this.updateLevel(valueToUpdate);
        Array.from(el.children).forEach(child => {
          child.checked = true;
        });
      } else {
        Array.from(el.children).forEach(child => {
          child.checked = false;
        });
      }
    });
    this.inputTarget.value = e.target.innerText;
  }

  updateLevel(selection) {
    const levelToAdd = selection === "custom-amount" ? this.customAmountTarget.value : selection;
    this.stimulate("Sketcher::AbbReflex#update_budget_by_spend_type", levelToAdd);
  }

  updateCustomAmount(e) {
    this.stimulate("Sketcher::AbbReflex#update_custom_budget", e.target.value);
  }

  populate_spend_levels() {
    const spending_levels = ["entry-level", "saturation", "domination"];
    this.optionTargets.forEach((el, i) => {
      const child = el.children[0];
      if (child.name == child.value) {
        child.checked = true;
        el.classList.toggle("active", true);
      } else if (!spending_levels.includes(child.value) && el.attributes.for.value == "custom-amount") {
        child.checked = true;
        el.classList.toggle("active", true);
      } else {
        child.checked = false;
        el.classList.toggle("active", false);
      }
    });
  }
}
