import ApplicationController from "../../../plus/src/controllers/application_controller";

export class DialogController extends ApplicationController {
  showModal(e) {
    e.preventDefault()
    this.element.querySelector("dialog").showModal()
  }

  closeModal(e) {
    e.preventDefault()
    this.element.querySelector("dialog").close()
  }
}
