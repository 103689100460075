import { Controller } from "stimulus";
import StimulusReflex from "stimulus_reflex";

export default class extends Controller {
  static targets = ["uploadSection", "fileSection", "fileInput", "submitButton", "uploadForm"];

  static values = {
    reflexAction: String,
    singleFileUpload: Boolean,
  };

  connect() {
    StimulusReflex.register(this);
  }

  openFileLibrary() {
    this.fileInputTarget.click();
  }

  submittedFiles() {
    return this.fileInputTarget.files;
  }

  validateUrl(e) {
    try {
      new URL(e.target.value);
      return this.enableSubmit();
    } catch (error) {
      return this.disableSumit();
    }
  }

  allowedFileExtensions() {
    return [
      "image/tiff",
      "image/jpeg",
      "image/png",
      "application/pdf",
      "application/zip",
      "video/quicktime",
      "video/mp4",
    ];
  }

  getFileFromLibrary(e) {
    const file = e.target.files[0];
    if (!this.allowedFileExtensions().includes(file.type)) {
      return this.buildErrorState();
    }
    this.appendUploadedFilesBoxes();
  }

  getFileBoxClone() {
    const fileBox = this.fileSectionTarget.getElementsByClassName("file-box")[0].cloneNode(true);
    return fileBox;
  }

  appendUploadedFilesBoxes() {
    this.appendClonedFileBoxes();
    this.uploadSectionTarget.classList.remove("show-error");
    this.uploadSectionTarget.classList.add("show-files");
    // We should only enable submit after the signed upload is ready
    // this.enableSubmit();
  }

  appendClonedFileBoxes() {
    this.fileInputTarget.files.forEach((file, idx) => {
      const fileBox = this.getFileBoxClone();
      const name = fileBox.getElementsByClassName("name")[0];
      //const removeAction = fileBox.getElementsByClassName("remove-action")[0]
      name.innerHTML = file.name;
      //removeAction.dataset.fileId = idx
      fileBox.classList.remove("hide");
      this.handleFileBoxAction(fileBox);
    });
  }

  handleFileBoxAction(fileBox) {
    if (this.singleFileUploadValue) {
      return this.fileSectionTarget.replaceChildren(fileBox);
    }
    return this.fileSectionTarget.append(fileBox);
  }

  removeFileFromList(e) {
    const { currentTarget } = e;
    const {
      dataset: { fileId },
    } = currentTarget;

    const dt = new DataTransfer();
    const filtered = Array.from(this.fileInputTarget.files).filter((file, idx) => idx !== parseInt(fileId));
    filtered.forEach(file => dt.items.add(file));
    this.fileInputTarget.files = dt.files;
    currentTarget.parentElement.remove();
  }

  buildErrorState() {
    this.uploadSectionTarget.classList.add("show-error");
  }

  enableSubmit() {
    this.submitButtonTarget.removeAttribute("disabled");
  }

  disableSumit() {
    this.submitButtonTarget.setAttribute("disabled", true);
  }

  async onSubmit() {
    Rails.fire(this.uploadFormTarget, "submit");
  }

  onError(e) {
    return this.handleAjaxResponse(e, "error");
  }

  onSuccess(e) {
    return this.handleAjaxResponse(e, "success");
  }

  async handleAjaxResponse({ detail }, responseType) {
    const response = detail[0];
    const { message } = response;
    await this.stimulate("UI::ToastReflex#show", responseType, message);
    if (this.hasReflexActionValue) {
      this.stimulateReflexAction();
    }
    return window.modal.close();
  }

  closeModal() {
    return window.modal.close();
  }

  async stimulateReflexAction() {
    return await this.stimulate(this.reflexActionValue);
  }
}
