import ApplicationController from "../application_controller";
import debounce from "lodash/debounce";

export default class extends ApplicationController {
  static values = {
    creatingCampaign: Boolean
  }

  connected() {
    this.debouncedCheck = debounce(this.checkBuildProcess.bind(this), 2000);

    this.checkLoop()
  }

  checkLoop() {
    if (!this.creatingCampaignValue && !this.campaignIsBuilt()) { return }

    if(this.campaignIsBuilt()) {
      this.resetCreatingFlag();
      return this.redirectToCampaign();
    }

    this.debouncedCheck();
  }

  checkBuildProcess() {
    this.stimulate("Sketcher::Reflex#morph_review");
  }

  campaignIsBuilt() {
    return !!this.data.get("campaignToken");
  }

  fromPublicPlanner() {
    return this.data.get("isOoh") == "true";
  }

  async resetCreatingFlag() {
    await this.stimulate("Sketcher::Reflex#update", { creating_campaign: false });
  }

  redirectToCampaign() {
    const token = this.data.get("campaignToken");
    if (this.fromPublicPlanner()) {
      const planner_token = this.data.get("sketchToken");
      return window.location.assign(`/ooh-planner/${planner_token}/export`);
    }
    window.location.assign(`/campaigns/${token}`);
  }
}
