import { Controller } from "stimulus";
import StimulusReflex from "stimulus_reflex";
export default class extends Controller {
  connect() {
    StimulusReflex.register(this);
  }

  checkSubmit(e) {
    const valueToValidate = document.getElementById("selected_location_id").value;
    if (!valueToValidate) {
      alert("Please make a selection from the dropdown");
      e.stopPropagation();
      e.preventDefault();
      return;
    }
  }
}
