import ApplicationController from "../application_controller";

export default class extends ApplicationController {
  connected() {
    console.log("connected");
  }

  updateBudgetDates(e) {
    e.preventDefault();
    const dates = JSON.parse(e.currentTarget.dataset.value);
    if (dates.length < 2) {
      return;
    }
    this.stimulate("Sketcher::AbbReflex#updateDates", e.currentTarget);
  }

  updatePeriod(e) {
    this.stimulate("Sketcher::AbbReflex#updateDuration", e.currentTarget);
  }
}
