import ApplicationController from "../application_controller";
import dayjs from "dayjs";

export default class extends ApplicationController {
  static targets = ["startDate", "endDate", "questionnaireDateRange", "circleIcon", "radioButton", "launch", "explore"];

  connected() {
    console.log("QuestionnaireController connected");
    this.updateWithCurrentState();
  }

  onDateRangeChange(e) {
    const dates = JSON.parse(e.currentTarget.dataset.value);
    this.buildDates(dates);
  }

  buildDates(dates) {
    const startDate = dates[0];
    const endDate = dates[1];
    this.startDateTarget.value = !!startDate ? this.formatDate(startDate) : null;
    this.endDateTarget.value = !!endDate ? this.formatDate(endDate) : null;
  }

  formatDate(dateString) {
    return dayjs(dateString).format("YYYY-M-D");
  }

  onClickLaunch(e) {
    if (this.launchTarget.checked) {
      this.launchTarget.checked = false;
      this.exploreTarget.checked = true;
    } else {
      this.launchTarget.checked = true;
      this.exploreTarget.checked = false;
    }
  }

  onClickExplore(e) {
    if (this.exploreTarget.checked) {
      this.exploreTarget.checked = false;
      this.launchTarget.checked = true;
    } else {
      this.exploreTarget.checked = true;
      this.launchTarget.checked = false;
    }
  }

  onClickIcon(e) {
    const target = e.currentTarget;
    const responseId = target.id.split("-")[0];
    const iconElement = document.getElementById(e.target.id);
    let color;

    if (
      target.getAttribute("style") ===
      "--fa-primary-color: #aaaaaa; --fa-secondary-color: #F5F5F5; --fa-secondary-opacity: 1;"
    ) {
      color = target.id.split("-")[1];
    }

    this.updateIconStyle(iconElement, color);
    this.updateIconValue(responseId, color);
  }

  onClickSelectAll(e) {
    e.preventDefault();
    const color = e.target.dataset.value;

    this.radioButtonTargets.forEach(x => {
      x.id.includes(color) ? (x.checked = true) : (x.checked = false);
    });

    this.circleIconTargets.forEach(x => {
      x.id.includes(color) ? this.updateIconStyle(x, color) : this.updateIconStyle(x, null);
    });
  }

  updateIconStyle(element, color) {
    switch (color) {
      case "green":
        element.style = "--fa-primary-color: #ffff; --fa-secondary-color: #44AC6B; --fa-secondary-opacity: 1;";
        element.style["--fa-primary-color"] = "#ffff";
        element.style["--fa-secondary-color"] = "#44AC6B";
        element.style["--fa-secondary-opacity"] = "1";
        break;
      case "yellow":
        element.style = "--fa-primary-color: #ffff; --fa-secondary-color: #FEB235; --fa-secondary-opacity: 1;";
        element.style["--fa-primary-color"] = "#ffff";
        element.style["--fa-secondary-color"] = "#FEB235";
        element.style["--fa-secondary-opacity"] = "1";
        break;
      case "red":
        element.style = "--fa-primary-color: #ffff; --fa-secondary-color: #E91630; --fa-secondary-opacity: 1;";
        element.style["--fa-primary-color"] = "#ffff";
        element.style["--fa-secondary-color"] = "#E91630";
        element.style["--fa-secondary-opacity"] = "1";
        break;
      default:
        element.style = "--fa-primary-color: #aaaaaa; --fa-secondary-color: #F5F5F5; --fa-secondary-opacity: 1;";
        element.style["--fa-primary-color"] = "#aaaaaa";
        element.style["--fa-secondary-color"] = "#f5f5f5";
    }
  }

  updateIconValue(responseId, color) {
    const uncheck = ["yellow", "red", "green"].filter(x => x != color);
    const check = color;
    const iconElement = document.getElementById(`${responseId}-${color}-radio`);
    iconElement.checked = true;
    uncheck.forEach(x => {
      const iconElement = document.getElementById(`${responseId}-${x}-radio`);
      const iconToChange = document.getElementById(`${responseId}-${x}`);
      this.updateIconStyle(iconToChange, null);
      iconElement.checked = false;
    });
  }

  updateWithCurrentState() {
    this.radioButtonTargets.forEach(x => {
      const responseId = x.id.split("-")[0];
      const color = x.id.split("-")[1];
      const iconElement = document.getElementById(`${responseId}-${color}`);
      if (x.checked) {
        this.updateIconStyle(iconElement, color);
      }
    });
  }
}
