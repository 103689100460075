import ApplicationController from "../../../plus/src/controllers/application_controller";
import PhotoSwipeLightbox from 'photoswipe/lightbox';

export class PhotoGalleryController extends ApplicationController {
  connected() {
    this.element.classList.add("pswp-gallery", "pswp-gallery--single-column")

    const links = this.element.querySelectorAll('a');
    links.forEach(link => {
      link.setAttribute('data-pswp-width', '800');
      link.setAttribute('data-pswp-height', '800');
    });

    const lightbox = new PhotoSwipeLightbox({
      gallery: `#${this.element.id}`,
      children: 'a',
      initialZoomLevel: 'fit',
      secondaryZoomLevel: 2,
      maxZoomLevel: 1,
      pswpModule: () => import('photoswipe')
    });
    lightbox.init();

  }
}
