import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  toggleEmail(e) {
    const { dataset } = e.target;
    const { emailSubtype, emailToToggle } = dataset;
    this.stimulate("AgencySettingsReflex#toggle_email", e.target, { subtype: emailSubtype, name: emailToToggle });
  }

  removeLogo(e) {
    this.stimulate("AgencySettingsReflex#remove_logo", e.target);
  }
}
