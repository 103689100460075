export default class GridAlerts {
  constructor(parentElementId) {
    this._parent = parentElementId;
  }

  get parentElement() {
    return document.getElementById(this._parent);
  }

  clear() {
    this.parentElement.innerHTML = "";
  }

  append(child) {
    this.parentElement.append(child);
  }

  hasErrors() {
    return this.parentElement.querySelectorAll().length > 0;
  }

  removeAlertById(id) {
    const alert = this.parentElement.querySelector(`#${id}`);
    if (!alert) {
      return;
    }
    alert.remove();
  }

  removeAllAlertsByRowId(rowId) {
    const alerts = this.parentElement.querySelectorAll(`[id^=${rowId}]`);
    alerts.forEach(el => el.remove());
  }

  createAlert(id, message, type = "error") {
    if (this.errorAlreadyShown(id)) {
      return;
    }
    const alertContainer = document.createElement("div");
    alertContainer.setAttribute("id", id);
    alertContainer.classList.add("alert", type, "small", "no-border");
    alertContainer.append(this.createIcon(this.alertIcon(type)));
    alertContainer.append(message);
    this.append(alertContainer);
  }

  alertIcon(type) {
    const icons = {
      error: "fa-exclamation-circle",
      warn: "fa-exclamation-triangle",
    };
    return icons[type];
  }

  createIcon(iconClass) {
    const iconContainer = document.createElement("div");
    iconContainer.classList.add("icon");
    const icon = document.createElement("i");
    icon.classList.add("far", iconClass);
    iconContainer.append(icon);
    return iconContainer;
  }

  errorAlreadyShown(id) {
    return !!document.getElementById(id);
  }
}
