import "flatpickr/dist/flatpickr.css";
import { Controller } from "stimulus";
import flatpickr from "flatpickr";

export default class extends Controller {
  initialize() {
    this.defaults = {
      dateFormat: this.dateFormat(),
      showMonths: this.showMonths(),
      appendTo: this.appendTo(),
      positionElement: this.positionElement(),
      maxDate: this.setMaxDate(),
      minDate: this.setMinDate(),
      onChange: dates => {
        const flatpickrElement = this.flatpickrElement();
        flatpickrElement.dataset.value = JSON.stringify(dates);
      },
    };
  }

  connect() {
    this.datepicker = flatpickr(this.element, this.defaults);
    this.setDates();
  }

  setDates() {
    if (!this.element.value) {
      return;
    }
    this.datepicker.setDate(this.element.value, true);
  }

  clear() {
    this.datepicker.clear();
  }

  flatpickrElement() {
    if (!this.checkDatasetAttribute("customElement")) {
      return this.element;
    }
    return this.getCustomElementToInitiate();
  }

  getCustomElementToInitiate() {
    return document.getElementById(this.element.dataset.customElement);
  }

  dateFormat() {
    return this.element.getAttribute("data-format") || "n/j/y";
  }

  showMonths() {
    return this.element.getAttribute("data-months-to-show") || 1;
  }

  setMinDate() {
    if (this.element.getAttribute("min-date-to-show")) {
      return new Date(this.element.getAttribute("min-date-to-show"));
    }
  }

  setMaxDate() {
    if (this.element.getAttribute("max-date-to-show")) {
      return new Date(this.element.getAttribute("max-date-to-show"));
    }
  }

  appendTo() {
    if (!this.checkDatasetAttribute("appendTo")) {
      return;
    }
    return document.getElementById(this.element.dataset.appendTo);
  }

  positionElement() {
    if (!this.checkDatasetAttribute("customElement")) {
      return;
    }
    return document.getElementById(this.element.dataset.customElement);
  }

  checkDatasetAttribute(attr) {
    return Object.keys(this.element.dataset).includes(attr);
  }
}
