import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["advertiserName", "agencyId"];

  async submit(e) {
    const name = this.advertiserNameTarget.value;
    const agencyId = this.agencyIdTarget.value;
    await this.stimulate("AdvertiserReflex#create", { name: name, agency_id: agencyId });
    alert(`Advertiser ${name} created, type it on search input to select`);
    window.modal.close();
  }
}
