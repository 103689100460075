import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  connected() {
    this.mixpanel = window.mixpanel;
  }

  track(e) {
    const eventName = e.currentTarget.dataset["eventName"];
    const eventProperties = e.currentTarget.dataset["eventProperties"]
      ? JSON.parse(e.currentTarget.dataset["eventProperties"])
      : {};
    this.mixpanel.track(eventName, eventProperties);
  }
}
