import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["amountInput", "creatives", "mainCreative", "carousel", "notesTruncate", "notesFull", "copyButton"];

  onClickAmount(e) {
    const val = e.target.dataset.value;
    this.amountInputTarget.value = val;
  }

  showCreative(index) {
    if (index < 0) {
      index = this.creativesTargets.length - 1;
    }
    if (index > this.creativesTargets.length - 1) {
      index = 0;
    }
    this.index = index;
    this.creativesTargets.forEach((el, i) => {
      if (i == index) {
        this.mainCreativeTarget.src = el.dataset.fullImage;
      }
    });
  }

  previousCreative() {
    this.showCreative(this.index - 1);
  }

  nextCreative() {
    this.showCreative(this.index + 1);
  }

  showFullNotes(e) {
    this.notesTruncateTarget.classList.add("hidden");
    this.notesFullTarget.classList.remove("hidden");
  }

  copyToClipboard(e) {
    document.getElementById("share-url").select();
    document.execCommand("copy");
    this.copyButtonTarget.textContent = "Copied!";
  }
}
