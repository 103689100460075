import { Controller } from "stimulus";
import StimulusReflex from "stimulus_reflex";
export default class extends Controller {

  static values = {
    redirectBasePath: String
  }

  connect() {
    StimulusReflex.register(this);
    const valueToClear = document.getElementById("selected_location_id").value;
    valueToClear && (document.getElementById("selected_location_id").value = "");
  }

  submit(e) {
    e.preventDefault();
    const valueToValidate = document.getElementById("selected_location_id").value;

    if (!valueToValidate) {
      alert("Please make a selection from the dropdown");
      e.stopPropagation();
      e.preventDefault();
      return;
    } else {
      let params = JSON.parse(valueToValidate);
      if (this.redirectBasePathValue === '/billboard-locations') {
        location.href = `${this.redirectBasePathValue}/${params.state_slug}/${params.city_slug}`;
      } else {
        location.href = `${this.redirectBasePathValue}/${params.city_slug}-${params.state_abbv}/`;
      }
    }
  }
}
