import ApplicationController from "../application_controller";
export default class extends ApplicationController {
  static targets = ["option", "input", "screens"];

  connected() {
    console.log("connected");
    this.checkScreenTypes();
  }

  selectScreenOption(e) {
    const checkedArray = [];
    this.screensTargets.forEach(element => {
      if (element.checked) checkedArray.push(element.name);
    });
    const screen_types_to_update = this.findScreentypes(checkedArray);
    this.updateUnitTypes(screen_types_to_update);
  }

  updateUnitTypes(screenType) {
    const value = JSON.stringify({ unit_types: screenType });
    this.stimulate("Sketcher::AbbReflex#update_screen_types", value);
  }

  findScreentypes(checkedArray) {
    if (checkedArray.length == 2) {
      return "both";
    } else if (checkedArray.includes("digital")) {
      return "digital";
    } else if (checkedArray.includes("static")) {
      return "static";
    } else {
      return "none";
    }
  }

  checkScreenTypes() {
    this.screensTargets.forEach(element => {
      if (element.value === "both") {
        element.checked = true;
      } else if (element.value === element.name) {
        element.checked = true;
      } else {
        element.checked = false;
      }
    });
  }
}
