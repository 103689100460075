import { Controller } from "stimulus";
import debounce from "lodash/debounce";
import StimulusReflex from "stimulus_reflex";

export default class extends Controller {
  static targets = ["options", "selected", "query"];

  initialize() {
    this.search = debounce(this.search.bind(this), 500);
    this.currentOverOption = null;
    this.currentOverPosition = 0;
  }

  connect() {
    StimulusReflex.register(this);
  }

  afterReflex() {
    this.setDefaultSelected();
  }

  setDefaultSelected() {
    const target = this.optionsTarget.querySelector(".option:not(.option-hidden)");
    if (target) {
      this.currentOverPosition = 1;
      target.classList.add("current-over");
      this.currentOverOption = target;
    }
  }

  hideOptions(_event) {
    this.optionsTarget.classList.add("select_options_container-hidden");

    if (this.changedOption) {
      this.changedOption = false;
      this.selectedTarget.dispatchEvent(new InputEvent("change"));
    }
  }

  filterOptions() {
    const selected = this.optionsTarget.querySelector(`.option-hidden`);
    selected?.classList.remove("option-hidden");

    const selectedID = this.selectedTarget.dataset.value;
    const newSelected = this.optionsTarget.querySelector(`[data-id="${selectedID}"]`);
    newSelected?.classList.add("option-hidden");
  }

  showOptions(event) {
    event.stopPropagation();
    this.currentOverOption = null;
    this.currentOverPosition = 0;

    document.querySelectorAll(".ui.select .select_options_container").forEach(element => {
      element.classList.add("select_options_container-hidden");
    });

    this.filterOptions();
    this.optionsTarget.classList.remove("select_options_container-hidden");
    this.setDefaultSelected();
  }

  search(_event) {
    if (!this.element.dataset.reflex) {
      return;
    }
    this.stimulate(this.element.dataset.reflex, {
      query: this.queryTarget.value,
      element_id: this.optionsTarget.id,
      on_add: this.element.dataset.onAdd,
    });
  }

  selectOption(event) {
    event.stopPropagation();
    const target = event.currentTarget;
    this.changedOption = true;
    this.queryTarget.value = target.dataset.name;
    this.selectedTarget.value = target.dataset.id;
    this.selectedTarget.dataset.value = target.dataset.id;
    this.selectedTarget.dispatchEvent(new InputEvent("input"));
    this.filterOptions();
    this.hideOptions();
  }

  keyUp(event) {
    let nextSelection = null;

    if (event.keyCode === 38) nextSelection = this.keyboardSelect(true);
    if (event.keyCode === 40) nextSelection = this.keyboardSelect();

    if (nextSelection) {
      console.log("position", this.currentOverPosition);
      this.currentOverOption = nextSelection;
      this.currentOverOption.classList.add("current-over");
      this.optionsTarget.scrollTop = 24 * this.currentOverPosition;
    }
  }

  keyDown(event) {
    // care only about ENTER and TAB
    if (![13, 9].includes(event.keyCode)) {
      return;
    }

    event.stopPropagation();
    if (this.currentOverOption) {
      this.currentOverOption?.click();
    } else {
      event.preventDefault();
    }
  }

  keyboardSelect(reverse = false) {
    let nextSelection = null;
    const options = Array.from(this.optionsTarget.querySelectorAll(".option")).filter(
      el => !el.classList.contains("option-hidden"),
    );

    this.currentOverPosition = 0;

    if (reverse) {
      options.reverse();
      this.currentOverPosition = options.length - 1;
    }

    if (this.currentOverOption) {
      let returnNext = false;

      nextSelection = options.find(el => {
        if (returnNext) return el;

        if (reverse) {
          this.currentOverPosition = this.currentOverPosition === 0 ? options.length - 1 : this.currentOverPosition - 1;
        } else {
          this.currentOverPosition = (this.currentOverPosition + 1) % options.length;
        }

        if (el.dataset.id == this.currentOverOption.dataset.id) returnNext = true;
      });
      this.currentOverOption.classList.remove("current-over");
    }

    if (!nextSelection) {
      nextSelection = options[0];
    }

    return nextSelection;
  }
}
