import { Controller } from "stimulus";
import QueryString from "query-string";

export default class extends Controller {
  static targets = ["navItem", "panel"];

  get params() {
    return QueryString.parse(window.location.search);
  }

  connect() {
    if (this.params.panel) {
      const panel = this.getPanel(this.params.panel);
      this.openPanel(panel);
    }
  }

  toggle(event) {
    const panel = this.getPanel(event.currentTarget.dataset.panel);
    const isOpen = panel.classList.contains("open");

    this.closePanels();

    if (!isOpen) {
      this.openPanel(panel);
    }
  }

  openPanel(panel) {
    const navItem = this.getNavItem(panel.dataset.panel);
    const { panel: panelName } = panel.dataset;
    const state = { ...history.state, panel: panelName };

    panel.classList.add("open");
    navItem.classList.add("open");
    this.paramsAdd("panel", panelName);
  }

  closePanels() {
    this.navItemTargets.forEach(panel => panel.classList.remove("open"));
    this.panelTargets.forEach(panel => panel.classList.remove("open"));
    this.paramsRemove("panel");
  }

  getPanel(panelName) {
    return this.panelTargets.find(target => target.dataset.panel === panelName);
  }

  getNavItem(panelName) {
    return this.navItemTargets.find(target => target.dataset.panel === panelName);
  }

  paramsAdd(key, val) {
    const state = { ...history.state, [key]: val };
    delete state.turbo;
    history.replaceState(state, null, `?${QueryString.stringify(state)}`);
  }

  paramsRemove(key) {
    const state = { ...history.state };
    delete state.turbo;
    delete state[key];
    history.replaceState(state, null, `?${QueryString.stringify(state)}`);
  }
}
