import { Controller } from "stimulus";
import { debounce } from "lodash-es";
import StimulusReflex from "stimulus_reflex";

export default class extends Controller {
  static targets = ["searchInput", "searchLoader"];

  connect() {
    StimulusReflex.register(this);
    this.search = debounce(this.search, 200);
  }

  search(e) {
    const input = this.searchInputTarget.value;
    console.log("Search:", input);
    this.stimulate("Stim::Plans::Show::GeographiesReflex#search", input);
  }

  clearSearch(e) {
    this.searchInputTarget.value = "";
    this.stimulate("Stim::Plans::Show::GeographiesReflex#search", "");
  }

  beforeSearch() {
    $(this.searchClearTarget).hide();
    $(this.searchLoaderTarget).show();
  }
  afterSearch() {
    $(this.searchClearTarget).show();
    $(this.searchLoaderTarget).hide();
  }

  beforeDestroy(e) {
    $(e)
      .find(".remove")
      .hide();
    $(e)
      .find(".loading")
      .show();
  }
  beforeCreate(e) {
    $(e)
      .find(".loading")
      .show();
  }
}
