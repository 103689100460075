import { Controller } from "stimulus";

export default class extends Controller {
  static values = { duration: Number };

  transition = 300;

  hasDuration() {
    if (!this.hasDurationValue) { return false; }

    return this.durationValue > 0;
  }

  connect() {
    if (this.hasDuration()) { this.handleElementWithDuration(); }
  }

  close(e) {
    e.preventDefault();
    this.element.remove();
  }

  handleElementWithDuration() {
    const duration = Number(this.durationValue);
    setTimeout(() => (this.element.style.opacity = "0"), duration);
    setTimeout(() => this.element.remove(), duration + this.transition);
  }
}
