import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["toggler", "content"];

  get collapsed() {
    return this._collapsed;
  }

  set collapsed(collapsed) {
    this._collapsed = collapsed;
  }

  connect() {
    this._collapsed = false;
    this.changeChevronDirection();
    this.toggleContent();
  }

  toggleCollapse() {
    this.collapsed = !this.collapsed;
    this.changeChevronDirection();
    this.toggleContent();
  }

  toggleContent() {
    if (this.collapsed) {
      return this.contentTarget.classList.add("hidden");
    }
    return this.contentTarget.classList.remove("hidden");
  }

  changeChevronDirection() {
    if (this.collapsed) {
      return this.toggleChevronDown();
    }
    return this.toggleChevronUp();
  }

  toggleChevronUp() {
    this.togglerTarget.classList.remove("fa-chevron-down");
    this.togglerTarget.classList.add("fa-chevron-up");
  }

  toggleChevronDown() {
    this.togglerTarget.classList.remove("fa-chevron-up");
    this.togglerTarget.classList.add("fa-chevron-down");
  }
}
