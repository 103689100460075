import ApplicationController from "../application_controller";

export default class extends ApplicationController {
  static targets = ["taxonomyItem", "categoryItem", "audienceItem"];

  toggleTaxonomy(e) {
    e.currentTarget.parentElement.classList.toggle("open");
  }

  handleCategory(e) {
    const category = e.currentTarget.closest(".first_level");
    if (this.isSearchResult(category)) {
      return this.toggleCategory(category);
    }
    if (this.isCategoryOpen(category)) {
      return this.closeCategory(category);
    }
    this.closeAllCategories();
    this.toggleCategory(category);
  }

  toggleCategory(category) {
    category.classList.toggle("open");
  }

  closeCategory(category) {
    category.classList.remove("open");
  }

  isSearchResult(category) {
    return Object.keys(category.dataset).includes("query");
  }

  isCategoryOpen(category) {
    return category.classList.contains("open");
  }

  closeAllCategories() {
    this.categoryItemTargets.forEach(i => i.classList.remove("open"));
  }

  addSegment(e) {
    this.stimulate("Sketcher::SegmentsReflex#createSketchSegment", e.currentTarget);
  }
}
