import { Controller } from "stimulus";
import StimulusReflex from "stimulus_reflex";

export class CreativeLibraryController extends Controller {
  static targets = ["filtersForm", "industrySelect"];

  connect() {
    StimulusReflex.register(this);
    this.makeBrowserBackButtonWorkWithTheCreativeModal();
  }

  copyLinkToClipboard(e) {
    e.preventDefault();
    const link = e.currentTarget.dataset["link"];
    navigator.clipboard.writeText(link);
    this.stimulate("UI::ToastReflex#show", "success", "Link copied to clipboard!");
  }

  async showCreativeModal(e) {
    e.preventDefault();
    const linkTag = e.currentTarget;
    const creativeId = linkTag.dataset["creativeId"];
    const payload = {
      component: "modal/creative_library/creative_modal_component",
      component_params: {
        creative_id: creativeId,
      },
    };
    await this.stimulate("ModalReflex#load_modal_component", payload);
    window.modal.open();

    const nextURL = linkTag.attributes.href.value;
    const nextTitle = "View more details about this creative";
    window.history.pushState({}, nextTitle, nextURL);
  }

  closeCreativeModal(e) {
    e.preventDefault();
    window.modal.close();

    const nextURL = e.currentTarget.attributes.href.value;
    const nextTitle = "OOH Creatives Library";
    window.history.pushState({ creativeModal: true }, nextTitle, nextURL);
  }

  submitForm(_e) {
    this.filtersFormTarget.requestSubmit();
  }

  filterByIndustryTag(e) {
    const industryId = e.target.dataset["industryId"];
    this.industrySelectTarget.value = industryId;
    this.industrySelectTarget.dispatchEvent(new Event("change"));
  }

  makeBrowserBackButtonWorkWithTheCreativeModal() {
    window.addEventListener("popstate", popStateEvent => {
      if (document.location.href.includes(window.location.origin + "/examples")) {
        this.closeCreativeModal(popStateEvent);
      }
    });
  }
}
