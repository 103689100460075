import consumer from "./consumer";

const start = () => {
  const planner_element = document.querySelector("#sketch_container");

  if (!planner_element) {
    return setTimeout(() => start(), 300);
  }

  const sketch_token = planner_element.dataset.plannerToken;
  const channel = {
    channel: "Sketcher::SketchChannel",
    sketch_token,
  };

  consumer.subscriptions.create(channel, {
    connected() {
      console.log("[ActionCable] Connected to Sketcher::SketchChannel.");
    },

    received(data) {
      const { html, element_id } = data;
      const el = document.getElementById(element_id);
      el.innerHTML = html;
    },
  });
};

const CampaignPlannerChannel = { start };
export default CampaignPlannerChannel;
