import ApplicationController from "../application_controller";
export default class extends ApplicationController {
  async generateContract(token) {
    const token_to_send = token;
    this.openLoadingModal();
    try {
      var url = `/toolkit/buying/audience/${token_to_send}/submit`;
      const response = await $.ajax({ url: url, method: "post" });
      if (response.status != 200) {
        var errors = response.errors;
        this.closeLoadingModal();
        this.stimulate("Sketcher::Reflex#morph_abb_error");
      } else {
        const { signature_request_url } = response;
        this.openHelloSign(signature_request_url);
      }
    } catch (error) {
      this.closeLoadingModal();
      throw error;
    }
  }

  onAbbSubmit(e) {
    const token = e.target.value;
    this.generateContract(token);
  }

  openHelloSign(signature_request_url) {
    const signature_url = signature_request_url;
    this.closeLoadingModal();
    HelloSign.init(AppConfig.helloSignClientId);
    HelloSign.open({
      skipDomainVerification: AppConfig.helloSignTestMode,
      url: signature_url,
      allowCancel: true,
      messageListener: this.onHelloSignMessage,
    });
  }

  onHelloSignMessage(data) {
    if (data.event == "signature_request_signed") {
      HelloSign.close();
      window.location = window.location + "/success";
    }
  }

  openLoadingModal() {
    var element = document.getElementById("abb-modal");
    element.classList.remove("hide");
    element.classList.add("open");
  }

  closeLoadingModal() {
    var element = document.getElementById("abb-modal");
    element.classList.remove("open");
    element.classList.add("hide");
  }
}
