import "flatpickr/dist/flatpickr.css";
import "flatpickr/dist/themes/airbnb.css";
import DatepickerController from "./datepicker_controller";
import flatpickr from "flatpickr";
import dayjs from "dayjs";

export default class extends DatepickerController {
  static targets = ["startDate", "endDate"];

  static values = {
    startDate: String,
    endDate: String,
  };

  connect() {
    this.reload();
  }

  reload() {
    let defaultDates;

    if (this.data.get("startDate")) {
      defaultDates = [this.data.get("startDate"), this.data.get("endDate")];
    } else if (this.hasStartDateTarget && this.startDateTarget.value !== "") {
      defaultDates = [
        dayjs(this.startDateTarget.value).format("MM/DD/YY"),
        dayjs(this.endDateTarget.value).format("MM/DD/YY"),
      ];
    } else {
      defaultDates = [];
    }

    this.datepicker = flatpickr(this.flatpickrElement(), {
      ...this.defaults,
      defaultDate: defaultDates,
      mode: "range",
      static: this.isStatic(),
    });
    this.configurePreSelectionWeeks();
    this.configureClearFooter();
  }

  isStatic() {
    if (this.element.dataset.staticPosition == "true") {
      return true;
    }

    return false;
  }

  startDateValueChanged() {
    if (this.datepicker) {
      this.datepicker.setDate([this.startDateValue, this.endDateValue], false);
    }
  }

  currentSelectedDates() {
    const flatpickrElement = this.flatpickrElement();
    const currentDates = JSON.parse(flatpickrElement.dataset.value || "[]");
    const start = currentDates[0];
    const end = currentDates[1];
    return { start, end };
  }

  applyWeekRange(e) {
    const { start } = this.currentSelectedDates();
    if (!start) {
      return alert("Select a start date first");
    }
    const weeksToAdd = Number(e.target.dataset.week);
    const startDate = this.createDateFromString(start);
    const endDate = this.addWeeksToStartDate(weeksToAdd);
    const dateRange = [this.datePickerParseDate(startDate), this.datePickerParseDate(endDate)];
    this.datepicker.setDate(dateRange, true);
  }

  addWeeksToStartDate(weeksToAdd) {
    const { start } = this.currentSelectedDates();
    const from = this.createDateFromString(start);
    const end = from.add(weeksToAdd, "week").startOf("week");
    return end;
  }

  createDateFromString(dateString) {
    /* return previous monday from the selected day */
    return dayjs(dateString)
      .startOf("week")
      .add(1, "day");
  }

  datePickerParseDate(date) {
    return this.datepicker.formatDate(new Date(date), this.dateFormat());
  }

  configurePreSelectionWeeks() {
    if (!this.checkDatasetAttribute("showWeekRange")) {
      return;
    }
    const calendar = this.datepicker.calendarContainer;
    const innerContainer = calendar.querySelector(".flatpickr-innerContainer");
    const weeksContainer = this.createDivElement();
    const weekList = this.createWeekList();
    weeksContainer.appendChild(weekList);
    calendar.insertBefore(weeksContainer, innerContainer);
  }

  createWeekList() {
    const weeks = ["4", "8", "12", "16"];
    const weekList = this.createListElement("flatpickr-weeks");
    weeks.forEach(week => {
      const action = `click->datepicker-range#applyWeekRange`;
      const text = `${week} weeks`;
      const link = this.createLinkElement(action, text, week);
      const listItem = this.createListItemElement("week");
      listItem.appendChild(link);
      weekList.appendChild(listItem);
    });
    return weekList;
  }

  configureClearFooter() {
    if (!this.checkDatasetAttribute("showClearFooter")) {
      return;
    }
    const calendar = this.datepicker.calendarContainer;
    const innerContainer = calendar.querySelector(".flatpickr-innerContainer");
    const footerContainer = this.createDivElement();
    footerContainer.setAttribute("class", "flatpickr-footer");
    const clearLink = this.createLinkElement("click->campaign-filter#clearDateRange", "Clear");
    footerContainer.appendChild(clearLink);
    innerContainer.append(footerContainer);
  }

  createDivElement() {
    const divElement = document.createElement("div");
    return divElement;
  }

  createListElement(className) {
    const listElement = document.createElement("ul");
    listElement.setAttribute("class", className);
    return listElement;
  }

  createListItemElement(className) {
    const listItem = document.createElement("li");
    listItem.setAttribute("class", className);
    return listItem;
  }

  createLinkElement(action, text, value) {
    const link = document.createElement("a");
    link.setAttribute("data-week", value);
    link.setAttribute("data-action", action);
    link.appendChild(document.createTextNode(text));
    return link;
  }

  onDateRangeChange(e) {
    const { value } = e.target.dataset;
    if (!value) {
      return;
    }

    const dates = JSON.parse(value);
    this.buildDates(dates);
  }

  buildDates(dates) {
    if (!this.hasStartDateTarget || !this.hasEndDateTarget) {
      return;
    }
    const startDate = dates[0];
    const endDate = dates[1];
    this.startDateTarget.value = !!startDate ? startDate : null;
    this.endDateTarget.value = !!endDate ? endDate : null;
  }
}
