import ApplicationController from "../../../plus/src/controllers/application_controller";
import { TabulatorFull as Tabulator } from "tabulator-tables";

export class InventoryHealthReportTable extends ApplicationController {
  static targets = ["table", "actions"];
  static values = {
    edits: Object,
  };
  connect() {
    super.connect();
    this.clearEdits = this.clearEdits.bind(this);
    this.cellEdited = this.cellEdited.bind(this);

    this.table = new Tabulator(this.tableTarget, {
      height:"811px",
      progressiveLoadScrollMargin: 500,
      layout: "fitColumns",
      progressiveLoad: "scroll",
      ajaxURL: "/inventory_health.json",
      paginationSize: 25,
      columns: [
        { title: "Face ID", field: "supplier_face_id" },
        { title: "Street Intersection", field: "name" },
        { title: "Market", field: "market_name" },
        { title: "Screen Type", field: "screen_type" },
        { title: "Size", field: "size", editor: "input", editable: this.checkEdit, formatter: this.cellFormatterClasses },
        { title: "Rate Card Price", field: "rate_card_price", editor: "input", editable: this.checkEdit, formatter: this.cellFormatterClasses},
        { title: "Impressions", field: "impressions", editor: "input", editable: this.checkEdit, formatter: this.cellFormatterClasses},
        { title: "Media Type", field: "media_type_id", formatter: this.mediaTypeFormatter},
        {
          title: "Media Subtype",
          field: "media_subtype_id",
          formatter: this.mediaSubtypeFormatter,
          editor: "list",
          editorParams: this.subTypeEditorParams(),
          editable: this.checkEdit,
        },
        { title: "Spot Length", field: "spot_length", editor: "input", editable: this.checkEdit, formatter: this.cellFormatterClasses },
        { title: "# of Spots", field: "total_spots", editor: "input", editable: this.checkEdit, formatter: this.cellFormatterClasses },
        { title: "Install Cost", field: "installation_cost", editor: "input", editable: this.checkEdit, formatter: this.cellFormatterClasses},
      ],
    });
    this.table.on("pageLoaded", this.clearEdits);
    this.table.on("cellEdited", this.cellEdited);
  }

  // edits value callbacks

  editsValueChanged() {
    if (Object.keys(this.editsValue).length !== 0) {
      this.actionsTarget.classList.remove('disabled')
    } else {
      this.actionsTarget.classList.add('disabled')
    }
  }

  getEditPayload() {
    return { data: Object.values(this.editsValue) };
  }

  async submit(e) {
    e.preventDefault()
    const form = e.target.form
    const formData = new FormData(form)
    const response = await fetch(form.action, {
      method: formData.get("_method") || form.method,
      body: JSON.stringify(this.getEditPayload()),
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
    const jsonResponse = await response.json()
    this.editsValue = {}
    this.handleResponse(jsonResponse)
  }

  handleResponse(responseBody) {
    let unitsWithError = []
    let updatedUnits = []
    const newData = this.table.getData().map(row => {
      Object.assign(row, { errors: null, updated: null })
      const unitWithError = responseBody.units_with_errors.find(e => e.id === row.id)

      if (unitWithError) {
        unitsWithError.push(unitWithError);
        Object.assign(row, { errors: unitWithError.error })
      }

      const updatedUnit = responseBody.updated_units.find(s => s.id === row.id)
      if (updatedUnit) {
        updatedUnits.push(unitWithError);
        Object.assign(row, { updated: updatedUnit.changes })
      }

      return row
    })
    if (unitsWithError.length > 0) {
      this.stimulate("UI::ToastReflex#show", "error", `${unitsWithError.length} units have errors, please review and try again`);
    }
    if (updatedUnits.length > 0) {
      this.stimulate("UI::ToastReflex#show_title_body", "success", `${updatedUnits.length} units updated successfully`, `Changes will be reflected at 7 AM UTC`);
    }

    this.table.replaceData(newData)
  }

  // Tabulator event handlers
  clearEdits(_pageno) {
    this.editsValue = {};
  }

  cellEdited(cell) {
    if (cell.getValue() === "") {
      return
    }

    const row = cell.getRow();
    const field = cell.getField();
    let value;
    if (field === "media_subtype_id") {
      value = cell.getValue().id;
    } else {
      value = cell.getValue();
    }

    const data = {
      [field]: value,
      id: row.getData().id,
    };

    const currentUnitEdit = this.editsValue[data.id];
    const currentEdits = this.editsValue
    let finalEditValue;
    if (currentUnitEdit === undefined) {
      finalEditValue = Object.assign(currentEdits, { [data.id]: data });
    } else {
      finalEditValue = Object.assign(currentEdits, { [data.id]: Object.assign(currentUnitEdit, data) })
    }

    this.editsValue = finalEditValue;
  }

  // Tabulator enable edits for cells with missing values
  checkEdit(cell) {
    const keyMap = {
      size: "size_score",
      rate_card_price: "rate_card_price_score",
      impressions: "impressions_score",
      media_subtype_id: "subtype_score",
      spot_length: "spot_length_score",
      total_spots: "number_of_spots_score",
      installation_cost: "installation_cost_score",
    };
    const data = cell.getRow().getData();
    // const element = cell.getElement()
    const field = cell.getField();
    // console.log({data})
    if (data.lost_score[keyMap[field]]) {
      cell.getElement().classList.add("cell-missing-score");
      return true;
    }
    return false;
  }

  // Tabulator cell formatters
  cellFormatterClasses(cell) {
    const data = cell.getRow().getData();
    if (data.errors && data.errors[cell.getField()]) {
      cell.getElement().classList.add("cell-error");
    }

    if (data.updated && data.updated[cell.getField()]) {
      cell.getElement().classList.add("cell-updated");
    }
    return cell.getValue();
  }

  mediaSubtypeFormatter(cell, _formatterParams, _onRendered) {
    // Same as cellFormatterClasses but for media_subtype_id I need both formatters
    // tried using this.cellFormatterClasses(cell) but I get a `is not a function` error
    // maybe tabulator internals does something weird when applying this function?
    const data = cell.getRow().getData();
    if (data.errors && data.errors[cell.getField()]) {
      cell.getElement().classList.add("cell-error");
    }
    if (data.updated && data.updated[cell.getField()]) {
      cell.getElement().classList.add("cell-updated");
    }
    return cell.getValue().label;
  }

  mediaTypeFormatter(cell, _formatterParams, _onRendered) {
    return cell.getValue().label;
  }

  // Tabulator render subtype dropdown search
  subTypeEditorParams() {
    return {
      async valuesLookup(cell) {
        const cellData = cell.getData();
        const response = await fetch(`/api/v1/media_types/get_subtypes?media_type_id=${cellData.media_type_id.id}`);
        const jsonData = await response.json();
        return jsonData.data.media_subtypes.map(st => {
          return {
            label: st.name,
            value: { id: st.id, label: st.name },
          };
        });
      },
    };
  }
}
