import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["form"];

  submitForm(event) {
    this.clearErrorMessages();

    let isValid = this.validateForm(this.formTarget);

    if (!isValid) {
      event.preventDefault();
    }
  }

  validateForm() {
    let isValid = true;
    const passwordFields = this.formTarget.querySelectorAll("input[type=password]");
    passwordFields.forEach(field => {
      if (field.value.length < 8) {
        this.setError(field, "Password must be 8+ characters long.");
        isValid = false;
      }
    });
    this.formTarget.querySelectorAll("#user_advertiser_name").forEach(field => {
      if (field.value.length === 0) {
        this.setError(field, "Advertiser name can not be empty");
        isValid = false;
      }
    });

    this.formTarget.querySelectorAll("#user_advertiser_industry").forEach(field => {
      if (field.value === "") {
        this.setError(field, "you need to select a industry");
        isValid = false;
      }
    });

    this.formTarget.querySelectorAll("#user_advertiser_url").forEach(field => {
      if (this.validateUrl(field.value) == null) {
        this.setError(field, "you need to select a industry");
        isValid = false;
      }
    });

    if (!isValid) {
      return false;
    }

    const password1 = passwordFields[0].value;
    const password2 = passwordFields[1].value;

    if (password1 !== password2) {
      this.setError(passwordFields[1], "Password does not match.");
      isValid = false;
    }

    return isValid;
  }

  validateUrl(url) {
    var expression = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
    var regex = new RegExp(expression);
    return url.match(regex);
  }

  clearErrorMessages() {
    this.formTarget.querySelectorAll(".error").forEach(containerElement => {
      containerElement.classList.remove("error");
    });

    this.formTarget.querySelectorAll(".error-message").forEach(errorElement => {
      errorElement.textContent = null;
    });
  }

  setError(field, message) {
    const parentDiv = field.parentElement;

    parentDiv.classList.add("error");
    parentDiv.querySelector(".error-message").textContent = message;

    field.classList.add("error");
    field.focus();
  }
}
