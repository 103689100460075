import { Controller } from "stimulus";
import StimulusReflex from "stimulus_reflex";

import Handsontable from "handsontable";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import duration from "dayjs/plugin/duration";
import accounting from "accounting";
import GridAlerts from "./grid_alerts";
import ManagedVendorsLimitedGridConfig from "./managed_vendor_limited_grid";
import FullGridConfig from "./full_vendor_grid";
import UpdategridConfig from "./update_grid";
import snakeCase from "lodash/snakeCase";
import debounce from "lodash/debounce";
import Dinero from 'dinero.js';

export default class extends Controller {
  static targets = ["gridDataHolder"];

  get dayjs() {
    dayjs.extend(utc);
    return dayjs.extend(duration);
  }

  get gridConfig() {
    const types = {
      limited: ManagedVendorsLimitedGridConfig,
      full: FullGridConfig,
      update: UpdategridConfig,
    };
    return types[this.data.get("gridType")] || ManagedVendorsLimitedGridConfig;
  }

  get gridHelper() {
    const config = JSON.parse(this.data.get("inventoryConfig"));
    return new this.gridConfig(config);
  }

  get gridAlert() {
    return new GridAlerts("alerts");
  }

  set grid(t) {
    return (this._grid = t);
  }

  get grid() {
    return this._grid;
  }

  get dateColumns() {
    return ["start_date", "end_date", "next_booked_date", "design_asset_due_date", "hold_expires_at"];
  }

  get currencyColumns() {
    return [
      "impressions",
      "price",
      "rate_card_price",
      "installation_cost",
      "production_cost",
      "price_for_duration",
      "calculated_price",
      "calculated_price_for_duration"
    ]
  }

  get inventoryFileData() {
    return this._inventoryFileData || [];
  }

  set inventoryFileData(inventoryFileData) {
    return (this._inventoryFileData = inventoryFileData);
  }

  async initialize() {
    this.mediaTypesWithSubtypes = await this.getMediaTypesAndSubtypes();
    this.unitScreenSubtypes = await this.getScreenSubtypes();
    this.defaults = {
      contextMenu: ["cut", "copy", "remove_row"],
      minRows: this.initialRowCount(),
      minSpareRows: this.defaultMinSpareRows(),
      height: this.defaultHeightValue(),
      width: this.element.offsetWidth,
      comments: true,
      autoWrapRow: false,
      autoWrapCol: false,
      rowHeaders: true,
      renderAllRows: false,
      wordWrap: false,
      stretchH: "all",
      width: "100%",
      colHeaders: this.getColHeaders(),
      columns: await this.configureColumns(),
      fixedColumnsLeft: 1,
      modifyColWidth: (width, col) => this.configureColumnsWidth(width, col),
      afterGetColHeader: this.configureMandatoryColumns.bind(this),
      afterValidate: this.afterValidate.bind(this),
      beforeKeyDown: this.onBeforeKeyDown.bind(this),
      beforeChange: this.onBeforeChange.bind(this),
      afterChange: this.onAfterChange.bind(this),
      afterSetDataAtCell: this.onAfterSetDataAtCell.bind(this),
      afterRemoveRow: this.onAfterRemoveRow.bind(this),
    };
  }

  async getMediaTypesAndSubtypes() {
    const response = await fetch("/api/v1/media_types/all_subtypes");
    const responseJson = await response.json();
    return responseJson.data.media_types;
  }

  async getScreenSubtypes() {
    const response = await fetch("/api/v1/media_types/screen_subtypes");
    const responseJson = await response.json();
    return responseJson.data.screen_subtypes;
  }

  isHoldOrInitial() {
    const taskType = this.data.get("supplierTaskType");
    return taskType == "hold" || taskType == "initial";
  }

  initialRowCount() {
    if (this.isHoldOrInitial()) {
      return this.data.get("inventoryCount");
    }
    return 15;
  }

  defaultMinSpareRows() {
    if (this.isHoldOrInitial()) {
      return;
    }
    return 1;
  }

  defaultHeightValue() {
    if (this.isHoldOrInitial()) {
      const calcHeight = this.data.get("inventoryCount") * 32;
      if (calcHeight > 375) return 375;
    }
    return 375;
  }

  async connect() {
    StimulusReflex.register(this);
    this.mediaTypesWithSubtypes = await this.getMediaTypesAndSubtypes();
    this.unitScreenSubtypes = await this.getScreenSubtypes();
    await this.getInventoryFileData();
    this.initializeInventoryFileData();
    this.validateNotEmptyRows();
    document.addEventListener("validated", this.writeValidationMessages);

    // set grid to global scope
    // needed for download template
    window.requestGrid = this.grid;
  }

  initializeInventoryFileData() {
    Handsontable.renderers.registerRenderer("custom.calculated-cpm", this.cpmRenderer);
    Handsontable.renderers.registerRenderer('custom.calculated-four-week-price', this.fourWeekPriceRenderer);
    Handsontable.renderers.registerRenderer('custom.calculated-price-for-duration', this.priceForDurationPriceRenderer);
    this.grid = new Handsontable(this.element, this.defaults);
    this.grid.loadData(this.parseInventoryFileData());
    this.initializeSubTypes();
    this.initializeScreenSubTypes();
  }

  initializeSubTypes() {
    this.grid.getSourceData().map((row, index) => this.setSubtypeSource(index, row.unit_type));
  }

  initializeScreenSubTypes() {
    this.grid.getSourceData().map((row, index) => this.setScreenSubtypeSource(index, row.screen_type));
  }

  disconnect() {
    document.removeEventListener("validated", this.writeValidationMessages);
  }

  writeValidationMessages = ({ detail }) => {
    try {
      this.gridAlert.clear();
      this.processBackendValidations(detail);
      this.grid.render();
    } catch (error) {
      this.catchAndLog(error, "Error writing validation errors");
    }
  };

  processBackendValidations({ gridFile, suspected }) {
    Object.entries(gridFile).forEach(row => this.processBackendValidation(row[0], row[1], suspected));
  }

  processBackendValidation(rowIdx, columns, suspected = false) {
    Object.entries(columns).forEach(column => this.handleCellMeta(rowIdx, snakeCase(column[0]), column[1], suspected));
  }

  handleCellMeta(rowIdx, prop, errors, suspected) {
    if (!suspected) {
      return this.processBackendErrors(rowIdx, prop, errors);
    }
    return this.processBackendSuspected(rowIdx, prop, errors);
  }

  processBackendErrors(rowIdx, prop, errors) {
    this.grid.setCellMeta(rowIdx, this.grid.propToCol(prop), "valid", false);
    this.setCommentCellWithErrors(rowIdx, prop, errors);
    return this.appendGridErrorForRowAndColumn(parseInt(rowIdx), prop, errors);
  }

  processBackendSuspected(rowIdx, prop, errors) {
    const columnIdx = this.gridHelper.findColumnIndexByKey(prop);
    const tdElement = this.grid.getCell(rowIdx, columnIdx);
    if (!tdElement) {
      return console.log(`Cell not found by line ${rowIdx}, prop ${prop}, column index ${columnIdx}`);
    }
    tdElement.setAttribute("class", "htSuspected");
    this.appendGridWarningForRowAndColumn(parseInt(rowIdx), prop, errors);
  }

  setCommentCellWithErrors(rowIdx, prop, errors) {
    try {
      // const commentsPlugin = this.grid.getPlugin('comments')
      // commentsPlugin.setCommentAtCell(rowIdx, this.grid.propToCol(prop), errors)
      // setting comment meta directly instead of using the comments plugin is way faster
      this.grid.setCellMeta(rowIdx, this.grid.propToCol(prop), "comment", { value: errors });
    } catch (error) {
      const message = "Error writing cells comments";
      this.catchAndLog(error, message);
    }
  }

  catchAndLog(error, message = "Unexpected error") {
    if (window.Rollbar) Rollbar.error(message, { exception: error.message, fullEvent: error });
    console.error(message, { exception: error });
    this.gridAlert.createAlert("base", "Error validating grid, please contact AdQuick support");
  }

  async getInventoryFileData() {
    const supplierTaskId = this.data.get("supplierTaskId");
    if (!supplierTaskId) {
      return;
    }
    const response = await fetch(`/api/v1/supplier_tasks/${supplierTaskId}/inventory_file`);
    const { data } = await response.json();
    this.inventoryFileData = data;
  }

  validateNotEmptyRows() {
    const notEmptyRows = this.filterNonEmptyRows();
    if (notEmptyRows.length == 0) {
      return;
    }
    const gridRowIds = Object.keys(notEmptyRows).map(row => Number.parseInt(row));
    this.grid.validateRows(gridRowIds);
  }

  parseInventoryFileData() {
    return this.inventoryFileData.reduce((acc, row) => {
      row["start_date"] = !_.isEmpty(row["start_date"])
        ? this.dayjs(row["start_date"])
            .utc()
            .format("M/D/YYYY")
        : "";
      row["end_date"] = !_.isEmpty(row["end_date"])
        ? this.dayjs(row["end_date"])
            .utc()
            .format("M/D/YYYY")
        : "";
      row["next_booked_date"] = !_.isEmpty(row["next_booked_date"])
        ? this.dayjs(row["next_booked_date"])
            .utc()
            .format("M/D/YYYY")
        : "";
      row["design_asset_due_date"] = !_.isEmpty(row["design_asset_due_date"])
        ? this.dayjs(row["design_asset_due_date"])
            .utc()
            .format("M/D/YYYY")
        : this.dayjs(row["start_date"])
            .utc()
            .subtract(2, "w")
            .format("M/D/YYYY");
      row["hold_expires_at"] = !_.isEmpty(row["hold_expires_at"])
        ? this.dayjs(row["hold_expires_at"])
            .utc()
            .format("M/D/YYYY")
        : "";
      acc.push(row);
      return acc;
    }, []);
  }

  async configureColumns() {
    const updatedConfig = await this.setUnitTypeSource(this.gridHelper.gridColumns());
    return updatedConfig.map(column => ({
      data: column.key,
      readOnly: column.read_only,
      type: column.type,
      dateFormat: column.dateFormat,
      allowEmpty: column.allowEmpty,
      validator: column.validator,
      source: column.source,
      renderer: column.renderer,
    }));
  }

  cpmRenderer = (instance, td, row, _col, _prop, _value, _cellProperties) => {
    try {
      const price = instance.getDataAtRowProp(row, "price");
      const priceForDuration = instance.getDataAtRowProp(row, "price_for_duration");
      const startDateColumnValue = instance.getDataAtRowProp(row, "start_date");
      const endDateColumnValue = instance.getDataAtRowProp(row, "end_date");

      if (startDateColumnValue === '' || endDateColumnValue === '' || (priceForDuration === '' && price === '')) {
        td.innerHTML = ''
        return td
      }

      const impressionsFourWeeks = instance.getDataAtRowProp(row, "impressions");
      let priceFourWeeks;

      if (priceForDuration) {
        const startDate = this.dayjs(startDateColumnValue);
        const endDate = this.dayjs(endDateColumnValue);
        const durationInDays = this.dayjs.duration(endDate.diff(startDate)).asDays();
        const pricePerDay = priceForDuration / durationInDays;
        priceFourWeeks = pricePerDay * 28.0;
      } else {
        priceFourWeeks = price;
      }

      const cpm = (priceFourWeeks / (impressionsFourWeeks / 1000.0))

      td.innerHTML = cpm.toFixed(2)
    } catch (error) {
      if (window.Rollbar) Rollbar.error(message, { exception: error.message, fullEvent: error });
      console.error(error);
      return "";
    }
  }

  fourWeekPriceRenderer = (instance, td, row, _col, _prop, _value, _cellProperties) => {
    try {
      const priceForDuration = instance.getDataAtRowProp(row, "price_for_duration");
      const startDateColumnValue = instance.getDataAtRowProp(row, "start_date");
      const endDateColumnValue = instance.getDataAtRowProp(row, "end_date");

      if (startDateColumnValue === '' || startDateColumnValue === null || endDateColumnValue === '' || endDateColumnValue === null || priceForDuration === '' || priceForDuration === null) {
        td.innerHTML = ''
        return td
      }

      const startDate = this.dayjs(startDateColumnValue);
      const endDate = this.dayjs(endDateColumnValue);
      const durationInDays = this.dayjs.duration(endDate.diff(startDate)).asDays() + 1; // adding one because they also count the last day as a full day

      const priceForDurationInCents = priceForDuration * 100;
      const pricePerDay = Dinero({ amount: priceForDurationInCents }).divide(durationInDays);
      const priceFourWeeks = pricePerDay.multiply(28);

      td.innerHTML = priceFourWeeks.toFormat('0.00');
    } catch (error) {
      if (window.Rollbar) Rollbar.error(error)
      console.error(error)
      return ''
    }
  }

  priceForDurationPriceRenderer = (instance, td, row, _col, _prop, _value, _cellProperties) => {
    try {
      const fourWeeksPrice = instance.getDataAtRowProp(row, "price");
      const startDateColumnValue = instance.getDataAtRowProp(row, "start_date");
      const endDateColumnValue = instance.getDataAtRowProp(row, "end_date");

      if (startDateColumnValue === '' || startDateColumnValue === null || endDateColumnValue === '' || endDateColumnValue === null || fourWeeksPrice === '' || fourWeeksPrice === null) {
        td.innerHTML = ''
        return td
      }

      const billingCycle = instance.getDataAtRowProp(row, "billing_cycle");
      let daysInCycle;

      if (billingCycle === 'monthly') {
        daysInCycle = 30;
      } else {
        daysInCycle = 28;
      }

      const startDate = this.dayjs(startDateColumnValue);
      const endDate = this.dayjs(endDateColumnValue);
      const durationInDays = this.dayjs.duration(endDate.diff(startDate)).asDays() + 1; // adding one because they also count the last day as a full day

      const fourWeeksPriceInCents = fourWeeksPrice * 100;
      const pricePerDay = Dinero({ amount: fourWeeksPriceInCents }).divide(daysInCycle);
      const priceForDuration = pricePerDay.multiply(durationInDays);

      td.innerHTML = priceForDuration.toFormat('0.00');
    } catch (error) {
      if (window.Rollbar) Rollbar.error(error)
      console.error(error)
      return ''
    }
  };

  async setUnitTypeSource(config) {
    return config.map(col => {
      if (col.key == "unit_type") {
        col.source = this.mediaTypesWithSubtypes.map(e => e.name);
      }

      if (col.key == "screen_subtype") {
        col.source = this.unitScreenSubtypes.map(e => e.name);
      }
      return col;
    });
  }

  getColHeaders() {
    return this.gridHelper.gridColumns().map(col => col.title);
  }

  configureColumnsWidth(width, col) {
    return this.gridHelper.configureColumnsWidth(width, col);
  }

  configureMandatoryColumns(_col, th) {
    const mandatoryColumns = this.getMandatoryColumns();
    const columnTitles = mandatoryColumns.map(c => c.title);
    if (!columnTitles.includes(th.textContent)) {
      return;
    }
    th.classList.add("mandatory");
  }

  getMandatoryColumns() {
    return this.gridHelper.gridColumns().filter(c => !c.allowEmpty);
  }

  afterValidate(isValid, value, row, prop, _source) {
    const id = this.buildIdForAlertMessages(row, prop);
    if (isValid) {
      return this.handleValidRowState(id, row, prop);
    }
    if (this.isRowEmptyAfterAplyingChange(row, value, prop)) {
      return true;
    }
    this.handleInvalidRowState(row, prop);
    if (this.grid.isEmptyRow(row)) {
      this.grid.alter("remove_row", row);
    }
  }

  isRowEmptyAfterAplyingChange(row, value, prop) {
    const currentRow = this.grid.getSourceData()[row];
    if (value && value.length == 0) {
      delete currentRow[prop];
    }
    return this.isRowEmpty(currentRow);
  }

  handleInvalidRowState(row, prop) {
    this.saveRowErrorOnGridData(row, prop);
    this.appendGridErrorForRowAndColumn(row, prop);
  }

  handleValidRowState(id, row, prop) {
    this.gridAlert.removeAlertById(id);
    this.clearRowErrorOnGridData(row, prop);
  }

  clearRowErrorOnGridData(row, prop) {
    const gridData = this.gridDataForRowWithErrorState(row);
    if (!gridData[row]) {
      return;
    }
    this.grid.getCellMetaAtRow(row).map(r => this.clearRowMeta(r));
    const propIsNotOnErrorState = !gridData[row]["has_error"].includes(prop);
    if (propIsNotOnErrorState) {
      return;
    }
    gridData[row]["has_error"] = gridData[row]["has_error"].filter(p => p != prop);
    this.saveGridDataHolder(this.filterNonEmptyRows(gridData));
  }

  clearRowMeta(row) {
    try {
      this.grid.setCellMetaObject(row.row, row.col, { valid: true, comment: {} });
    } catch (error) {
      console.error("Failed clearing row meta", error);
    }
  }

  onBeforeKeyDown(event) {
    // keycode 46 == delete
    // keycode 8 == backspace
    const key = event.keyCode;
    const deleteOrBackspace = key == 8 || key == 46;
    if (!deleteOrBackspace) return;

    const selectedRange = this.grid.getSelectedRange();
    const totalColumnSize = this.gridHelper.gridColumns().length;
    // Only triggers the row deletion if the user selected all the columns, otherwise simply run the normal `delete` event and just remove the selected cells contents
    const rowsToRemove = selectedRange
      .map(r => {
        const firstCol = r.from.col;
        const lastCol = r.to.col;
        const [sortedFirst, sortedLast] = [firstCol, lastCol].sort();
        if (sortedLast + 1 - sortedFirst == totalColumnSize) {
          // check to see if the selected columns match the total columns on the grid
          const min = Math.min(r.from.row, r.to.row); // check for min/max in case the selection happened from last to first
          const max = Math.max(r.from.row, r.to.row);
          return { start: min, end: max };
        }
      })
      .filter(r => r !== undefined);
    if (rowsToRemove.length > 0) {
      const ranges = rowsToRemove.map(r => this.range(r.start, r.end));
      if (window.confirm(`Are you sure you want to remove the highlighted rows?`)) {
        event.stopImmediatePropagation();
        event.preventDefault();
        // [r, 1] means starting from row `r` delete 1 row
        // this is to make the handling of non contiguous selections easier
        const reverseOrdered = ranges
          .flatMap(r => r)
          .reverse()
          .map(r => [r, 1]);
        this.grid.alter("remove_row", reverseOrdered);
        this.grid.deselectCell();
      } else {
        event.stopImmediatePropagation();
      }
    }
    this.grid.render();
  }

  range(start, end) {
    return Array.from(new Array(end - start + 1).keys()).map(num => {
      return num + start;
    });
  }

  saveRowErrorOnGridData(row, prop) {
    const gridData = this.gridDataForRowWithErrorState(row);
    const propAlreadyHasError = gridData[row]["has_error"].includes(prop);
    if (propAlreadyHasError) {
      return;
    }
    gridData[row]["has_error"] = [...gridData[row]["has_error"], prop];
    this.saveGridDataHolder(this.filterNonEmptyRows(gridData));
  }

  gridDataForRowWithErrorState(rowId) {
    return this.grid.getSourceData().reduce((gridData, row, currentRowId) => {
      if (currentRowId == rowId) {
        row = this.rowWithErrorState(row);
      }
      gridData.push(row);
      return gridData;
    }, []);
  }

  rowWithErrorState(row) {
    const hasError = (row["has_error"] && !Array.isArray(row["has_error"])) || [];
    row["has_error"] = hasError;
    return row;
  }

  buildIdForAlertMessages(row, prop) {
    return `row_${row}_${prop}`;
  }

  appendGridErrorForRowAndColumn(row, prop, customMessage) {
    try {
      const id = this.buildIdForAlertMessages(row, prop);
      const column = this.gridHelper.findGridColumnByKey(prop);
      if (!column) {
        console.log(`Not found row: ${row}, prop: ${prop}`);
        return;
      }

      const error = customMessage ? customMessage : column.errorMessage;
      const message = `Error on row ${row + 1}: Column ${column.title} ${error}`;
      this.gridAlert.createAlert(id, message);
    } catch (error) {
      const message = "Error writing appending grid errors on row";
      this.catchAndLog(error, message);
    }
  }

  appendGridWarningForRowAndColumn(row, prop, message) {
    const id = `warn_${this.buildIdForAlertMessages(row, prop)}`;
    const column = this.gridHelper.findGridColumnByKey(prop);
    const alertMessage = `Warning on row ${row + 1}: Column ${column.title} ${message}`;
    this.gridAlert.createAlert(id, alertMessage, "warn");
  }

  onBeforeChange(changes, source) {
    if (source == "loadData") {
      return;
    }
    changes.forEach(c => this.parseDates(c));
  }

  parseDates(c) {
    if (!this.dateColumns.includes(c[1])) {
      return;
    }
    if (!c[3]) {
      return;
    }
    c[3] = this.dayjs(c[3])
      .utc()
      .format("M/D/YYYY");
  }

  onAfterChange(changes, source) {
    if (source == "loadData" && !this.grid) {
      return;
    }

    const units = this.filterNonEmptyRows();
    this.saveGridDataHolder(units);

    if (source == "edit" || source == "CopyPaste.paste" || source == "Autofill.fill") {
      this.handleUnitTypeChanges(changes);
      const changedRows = changes.map(c => c[0]);
      this.grid.validateRows(changedRows);
      this.debouncedSave();
    }
  }

  debouncedSave() {
    debounce(() => {
      this.stimulate("Plus::AdquickRequestsReflex#save", { grid_file: this.parseInventoryFile() });
    }, 100)();
  }

  parseInventoryFile() {
    try {
      const supplierTaskId = this.data.get("supplierTaskId");
      const file = window.sessionStorage.getItem(`task_${supplierTaskId}_data`);
      return JSON.parse(file);
    } catch (error) {
      return [];
    }
  }

  saveGridDataHolder(gridData) {
    const supplierTaskId = this.data.get("supplierTaskId");
    window.sessionStorage.setItem(`task_${supplierTaskId}_data`, JSON.stringify(gridData));
  }

  handleUnitTypeChanges(changes) {
    changes.map(change => {
      const [row, prop, _oldValue, newValue] = change;
      if (prop == "unit_type") {
        this.setSubtypeSource(row, newValue);
      }

      if (prop == "screen_type") {
        this.setScreenSubtypeSource(row, newValue);
      }
    });
  }

  async setSubtypeSource(row, value) {
    const newSubtypeSource = this.mediaTypesWithSubtypes.find(
      t => t.name == value || t.value == value || t.special_cases.includes(value),
    );
    if (newSubtypeSource) {
      const options = newSubtypeSource.media_subtypes.map(e => e.name);
      options.unshift("");
      this.grid.setCellMeta(row, this.grid.propToCol("unit_subtype"), "source", options);
    }
  }

  async setScreenSubtypeSource(row, value) {
    const newScreenSubtypeSource = this.unitScreenSubtypes.filter(t => t.screen_type == value);
    if (newScreenSubtypeSource) {
      const options = newScreenSubtypeSource.map(e => e.name);
      options.unshift("");
      this.grid.setCellMeta(row, this.grid.propToCol("screen_subtype"), "source", options);
    }
  }

  filterNonEmptyRows(source = null) {
    const grid_data = source ? source : this.grid.getSourceData();
    let filtered_data;
    filtered_data = grid_data.map(row => {
      return this.removeEmptyAttribute(row, "billing_cycle");
    });
    const rows = this.createUUIDForGridRows(filtered_data);
    return rows.filter(row => !this.isRowEmpty(row));
  }

  isRowEmpty(row) {
    if (!row) {
      return true;
    }
    const excludeColumns = ["row_id", "has_error"];
    return Object.keys(row).filter(k => !excludeColumns.includes(k)).length == 0;
  }

  createUUIDForGridRows(rows) {
    return rows.reduce((acc, row) => {
      row["row_id"] = row["row_id"] ? row["row_id"] : this.createUUID();
      acc.push(row);
      return acc;
    }, []);
  }

  createUUID() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, c => {
      const r = (Math.random() * 16) | 0,
        v = c == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  onAfterSetDataAtCell(changes, _source) {
    changes.forEach(c => this.formatCurrenyColumns(c));
  }

  onAfterRemoveRow(index, amount, physicalRows, source) {
    physicalRows.map(row => {
      this.gridAlert.removeAllAlertsByRowId(`row_${row}`);
      this.clearRowErrorOnGridData(row);
    });
    const units = this.filterNonEmptyRows();
    this.saveGridDataHolder(units);
    this.stimulate("Plus::AdquickRequestsReflex#save", { grid_file: this.parseInventoryFile() });
  }

  formatCurrenyColumns(c) {
    if (!this.currencyColumns.includes(c[1])) {
      return;
    }
    if (!c[3]) {
      return;
    }
    if (c[3].toString().replace(/\D/g, "") == "") {
      return;
    }
    c[3] = accounting.formatMoney(c[3]).replace(/[\,\$]+/g, "");
  }

  removeEmptyAttribute(row, attributeName) {
    try {
      return _.omit(row, attributeName);
    } catch (error) {
      console.error(`Error cleaning up attribute ${attributeName}. Falling back to current data`);
      return row;
    }
  }

  isntShortFlight() {
    this.data.get('shortFlight') !== 'true'
  }
}
