import { Controller } from "stimulus";
export default class extends Controller {
  static targets = ["toggle"];
  static classes = ["change"];

  connect() {}

  toggle() {
    this.toggleTargets.forEach(element => element.classList.toggle(this.changeClass));
  }
}
