import { handleResponse, formatErrors } from './response';

export const get = function (url, token, params = null) {
  let headers = {};

  if (token) {
    headers['Authorization'] = 'Bearer ' + token;
  }

  if (params) {
    url = `${url}?${paramsToQuery(params)}`;
  }

  return fetch(url, {credentials: 'same-origin'})
    .then(handleResponse)
    .catch(error => {
      if (error.name == 'ApiError') {
        return Promise.reject(formatErrors(error.response));
      } else {
        console.error('fetch error', error);
        return Promise.reject(error);
      }
    });
};

function paramsToQuery(params) {
    return Object.keys(params).reduce((query, key) => {
      if (params[key]) query.push(`${key}=${params[key]}`);
      return query;
    }, []).join("&");
}

export const post = function (url, data, token) {
  let headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  };

  if (token) {
    headers['Authorization'] = 'Bearer ' + token;
  }

  return fetch(url, {
    method: 'post',
    headers: headers,
    body: JSON.stringify(data),
    credentials: 'same-origin',
  })
    .then(handleResponse)
    .catch(error => {
      if (error.name == 'ApiError') {
        return Promise.reject(formatErrors(error.response));
      } else {
        return Promise.reject(error.message);
      }
    });
};

export const put = function (url, data, token) {
  let headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  };

  if (token) {
    headers['Authorization'] = 'Bearer ' + token;
  }

  return fetch(url, {
    method: 'put',
    headers: headers,
    body: JSON.stringify(data),
    credentials: 'same-origin',
  })
    .then(handleResponse)
    .catch(error => {
      if (error.name == 'ApiError') {
        return Promise.reject(formatErrors(error.response));
      } else {
        console.log(error);
      }
    });
};

export const patch = function (url, data, token) {
  let headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  };

  if (token) {
    headers['Authorization'] = 'Bearer ' + token;
  }

  return fetch(url, {
    method: 'PATCH',
    headers: headers,
    body: JSON.stringify(data),
    credentials: 'same-origin',
  })
    .then(handleResponse)
    .catch(error => {
      if (error.name == 'ApiError') {
        return Promise.reject(formatErrors(error.response));
      } else {
        console.log(error);
      }
    });
};

export const del = function (url, data) {
  let headers = {};

  if (data) url = `${url}?${paramsToQuery(data)}`;

  return fetch(url, {
    method: 'delete',
    headers: headers,
    credentials: 'same-origin',
  })
    .then(handleResponse)
    .catch(error => {
      if (error.name == 'ApiError') {
        return Promise.reject(formatErrors(error.response));
      } else {
        console.log(error);
      }
    });
};

export const delWithBody = function (url, data) {
  let headers = {};

  return fetch(url, {
    method: 'delete',
    headers: headers,
    credentials: 'same-origin',
    body: JSON.stringify(data)
  })
    .then(handleResponse)
    .catch(error => {
      if (error.name == 'ApiError') {
        return Promise.reject(formatErrors(error.response));
      } else {
        console.log(error);
      }
    });
};

export const postWithPayloadError = async function (url, data, token) {
  let headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  };

  if (token) {
    headers['Authorization'] = 'Bearer ' + token;
  }
  return fetch(url, {
    method: 'post',
    headers: headers,
    body: JSON.stringify(data),
    credentials: 'same-origin',
  })
    .then((response) => {
      return new Promise((resolve, reject) => {
        const func = response.status < 400 ? resolve : reject;
        response.json().then(data => func({ data }));
      })
    })
};
