import ApplicationController from "../../../../plus/src/controllers/application_controller";

export class ReviewRfpController extends ApplicationController {
  updateCurrency(e) {
    const currency = e.target.querySelector('.selected-option').dataset['id'];
    const supplierTaskId = this.element.dataset['supplierTaskId'];

    this.stimulate("Plus::AdquickRequestsReflex#update_rfp_currency", { supplier_task_id: supplierTaskId, currency: currency })
  }
}
