import RfpGridConfig from "./rfp_grid_config";

export default class UpdateGrid extends RfpGridConfig {
  constructor(config) {
    super(config);
    this._managed_or_unmanaged = config.managed_or_unmanaged;
  }

  unitTypes() {
    // filled by handsontableController.getMediaTypesAndSubtypes()
    return [];
  }

  validateLat(value, callback) {
    const parsed = Number.parseFloat(value);
    if (isNaN(parsed)) {
      return callback(false);
    }
    if (parsed < 23 || parsed > 53) {
      return callback(false);
    }
    return callback(true);
  }

  validateLon(value, callback) {
    const parsed = Number.parseFloat(value);
    if (isNaN(parsed)) {
      return callback(false);
    }
    if (parsed < -128 || parsed > 35) {
      return callback(false);
    }
    return callback(true);
  }

  validateName(value, callback) {
    if (!value) {
      return callback(false);
    }
    if (value && value.length < 5) {
      return callback(false);
    }
    return callback(true);
  }

  validateSize(value, callback) {
    if (!value) {
      return callback(false);
    }
    if (value && value.length > 15) {
      return callback(false);
    }
    return callback(true);
  }

  validateImpressions(value, callback) {
    const parsed = Number.parseInt(value);
    if (isNaN(parsed)) {
      return callback(false);
    }
    if (parsed < 999) {
      return callback(false);
    }
    return callback(true);
  }

  validateInstallationCost(value, callback) {
    const parsed = Number.parseFloat(value);
    if (isNaN(parsed)) {
      return callback(false);
    }
    if (parsed > 999999) {
      return callback(false);
    }
    return callback(true);
  }

  validateScreenType(value, callback) {
    if (!value) {
      return callback(false);
    }
    const isValid = value && value.toLowerCase() != "static" && value.toLowerCase() != "digital";
    if (isValid) {
      return callback(false);
    }
    return callback(true);
  }

  gridColumns() {
    const columns = [
      {
        key: "supplier_face_id",
        title: "Face ID",
        read_only: true,
        type: "text",
        allowEmpty: false,
        validator: this.checkAllowEmpty,
        errorMessage: this.errorMessages.required,
      },
      {
        key: "lat",
        title: "Latitude",
        read_only: false,
        type: "numeric",
        allowEmpty: false,
        validator: this.validateLat,
        errorMessage: this.errorMessages.latitude,
      },
      {
        key: "lon",
        title: "Longitude",
        read_only: false,
        type: "numeric",
        allowEmpty: false,
        validator: this.validateLon,
        errorMessage: this.errorMessages.longitude,
      },
      {
        key: "name",
        title: "Street Intersection",
        read_only: false,
        type: "text",
        allowEmpty: false,
        validator: this.validateName,
        errorMessage: this.errorMessages.name,
      },
      {
        key: "size",
        title: "Size",
        read_only: false,
        type: "text",
        allowEmpty: false,
        validator: this.validateSize,
        errorMessage: this.errorMessages.size,
      },
      {
        key: "impressions",
        title: "4 week Impressions (0+)",
        read_only: false,
        type: "numeric",
        allowEmpty: false,
        validator: this.validateImpressions,
        errorMessage: this.errorMessages.impressions,
      },
      {
        key: "price",
        title: "4 Week Price",
        read_only: false,
        type: "numeric",
        allowEmpty: false,
        errorMessage: this.errorMessages.numeric_required,
      },
      {
        key: "rate_card_price",
        title: "4 Week Rate Card Price",
        read_only: false,
        type: "numeric",
        allowEmpty: false,
        errorMessage: this.errorMessages.numeric,
      },
      {
        key: "installation_cost",
        title: "Installation Cost",
        read_only: false,
        type: "numeric",
        allowEmpty: false,
        validator: this.validateInstallationCost,
        errorMessage: this.errorMessages.installation_cost,
      },
      {
        key: "production_cost",
        title: "Production cost",
        read_only: false,
        type: "numeric",
        allowEmpty: false,
        errorMessage: this.errorMessages.numeric_required,
      },
      {
        key: "screen_type",
        title: "Static or Digital",
        read_only: false,
        type: "text",
        allowEmpty: false,
        validator: this.validateScreenType,
        errorMessage: this.errorMessages.screen_type,
      },
      {
        key: "unit_type",
        title: "Unit Type",
        read_only: false,
        type: "dropdown",
        source: this.unitTypes(),
        allowEmpty: false,
        validator: this.unitTypeValidator(this.config),
        errorMessage: this.errorMessages.unit_type,
      },
      {
        key: "unit_subtype",
        title: "Unit SubType",
        read_only: this.readonly(),
        type: "dropdown",
        source: [],
        allowEmpty: false,
        errorMessage: this.errorMessages.unit_subtype,
      },
      {
        key: "direction",
        title: "Facing",
        read_only: false,
        type: "text",
        allowEmpty: true,
        validator: this.checkAllowEmpty,
      },
      {
        key: "orientation",
        title: "Read",
        read_only: false,
        type: "text",
        allowEmpty: true,
        validator: this.checkAllowEmpty,
      },
      {
        key: "illuminated",
        title: "Illuminated",
        read_only: this.readonly(),
        type: "dropdown",
        source: ["16 HRS", "17 HRS", "18 HRS", "19 HRS", "20 HRS", "21 HRS", "22 HRS", "23 HRS", "24 HRS", "NO"],
        allowEmpty: false,
        validator: this.checkAllowEmpty,
        errorMessage: this.errorMessages.required,
      },
      { key: "tab_panel_id", title: "Geopath Panel ID", read_only: false, type: "text", allowEmpty: true },
      {
        key: "spot_length",
        title: "Spot Length (in Seconds)",
        read_only: false,
        type: "numeric",
        allowEmpty: true,
        errorMessage: this.errorMessages.numeric,
      },
      {
        key: "total_spots",
        title: "Number of Slots (if digital)",
        read_only: false,
        type: "numeric",
        allowEmpty: true,
        errorMessage: this.errorMessages.numeric,
      },
      {
        key: "loop_length",
        title: "Number of Loops (if digital)",
        read_only: false,
        type: "numeric",
        allowEmpty: true,
        errorMessage: this.errorMessages.numeric,
      },
    ];

    return columns;
  }

  configureColumnsWidth(index) {
    if (index == 1 || index == 2) {
      return 100;
    }
    if (index == 3) {
      return 450;
    }
    if (index == 13 || index == 14 || index == 15) {
      return 200;
    }
    return 120;
  }
}
