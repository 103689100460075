import { Controller } from "stimulus";
import StimulusReflex from "stimulus_reflex";
import dragula from "dragula";

// TODO: make this controller more generic by removing the specific code inside the "reorder" function
export default class extends Controller {
  connect() {
    StimulusReflex.register(this);
    // TODO: we probably can remove this this.sortable assignment
    this.sortable = dragula([this.element], {
      moves: function(_el, _container, handle) {
        return handle.classList.contains("fa-grip-vertical");
      },
      mirrorContainer: this.element,
    }).on("drop", this.reorder.bind(this));
  }

  reorder(el, target, _source, sibling) {
    let position;
    if (sibling.nodeName.toLowerCase() == "input") {
      // sometimes the "drop event" is triggered on a rails-generated hidden field
      position = sibling.previousElementSibling.dataset.columnId;
    } else {
      position = sibling.dataset.columnId;
    }
    [...target.getElementsByClassName("letter")].forEach(
      el => (el.innerHTML = "<i class='fal fa-spinner fa-spin'></i>"),
    );
    this.stimulate("Plus::ExportTemplates::ListReflex#reorder", el.dataset.columnId, position);
  }
}
