import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["createCampaignButton", "errors", "emailInput"];
  static values = { isGuest: Boolean, sketchToken: String, possibleLeadRegex: String, invalidRegex: String };

  connected() {
    this._isGuest = JSON.parse(this.data.get("isGuest"));
    this._sketchToken = this.data.get("oohPlannerToken");
  }

  loadingContent() {
    return "<i class='far fa-spinner icon_left loading-spin' style='color: white !important'></i><span style='margin-left: 15px;'>Creating Campaign</span>";
  }

  defaultContent() {
    return "<i class='far fa-magic'></i><span style='margin-left: 10px;'>Create Smart Campaign</span>";
  }

  startLoading() {
    this.createCampaignButtonTarget.innerHTML = this.loadingContent();
  }

  finishLoading() {
    this.createCampaignButtonTarget.innerHTML = this.defaultContent();
  }

  handleSmartCampaignCreate() {
    let email = this.emailInputTarget.value;

    this.screen(email);
    this.createSmartCampaign();
  }

  checkErrors() {
    const errorMessages = Array.from(this.errorsTarget.children);
    if (errorMessages.length > 0) {
      throw "has errors";
    }
  }

  showEmailError() {
    alert("Please enter a valid buisness email.");
  }

  screen(email) {
    if (!email) {
      this.showEmailError();
      e.preventDefault(e);
      return;
    }
    if (screenEmail(email) && !screenPersonal(email)) {
      $(".work_email_message").addClass("open");
      this.showEmailError();
      e.preventDefault(e);
    } else if (screenPersonal(email)) {
      location.href = `/signup?email=${email}`;
    }
  }

  async createSmartCampaign() {
    try {
      this.startLoading();
      await this.stimulate("Sketcher::Reflex#morph_error");
      this.checkErrors();
      this.finishLoading();
      this.handleCampaignResponse();
    } catch (error) {
      this.finishLoading();
      console.log(error);
    }
  }

  async generateCampaign() {
    try {
      await this.stimulate("Sketcher::Reflex#create_sketch_campaign", { visitor_email: this.emailInputTarget.value });
    } catch (error) {
      console.log(error);
    }
  }

  async handleCampaignResponse() {
    this.generateCampaign();
    this.stimulate("Sketcher::Reflex#wait_for_campaign_create");
  }

  waitForCampaignCreateSuccess(e) {
    let token = document.querySelector("#created-campaign-data input").value;
    location.href = `/campaigns/${token}`;
  }
}
