import debounce from "lodash/debounce";
import { Controller } from "stimulus";
import StimulusReflex from "stimulus_reflex";
import QueryString from "query-string";

const loadingClass = "loading";

export default class extends Controller {
  static targets = [
    "taxonomyItem",
    "categoryItem",
    "audienceItem",
    "loadingItem",
    "score",
    "query",
    "queryContainer",
    "persona",
    "heatmapScore",
    "provider",
    "sortBy",
  ];
  taxonomyOpen = null;
  categoryOpen = null;
  filtering = "";
  is_filtering = false;
  reflexClass = null;
  heat_map_selected_ids = [];

  initialize() {
    this.mixpanel = window.mixpanel;
    this.reflexClass = this.data.get("reflexClass");
    this.containerSelector = this.data.get("container");
    window.removeAudienceLoadingListener = this.removeLoading.bind(this);
    window.addEventListener("panel-open", this.openPanel.bind(this));
    window.addEventListener("panel-close", this.closePanel.bind(this));
  }

  closePanel(e) {
    if (e.detail.name != "audiences") return;

    this.stimulate(`${this.reflexClass}#remove_references`);
    window.heat_map_score_ids = this.heat_map_selected_ids;
  }

  openPanel(e) {
    if (e.detail.name != "audiences") return;

    this.stimulate(`${this.reflexClass}#show_references`);
    this.heat_map_selected_ids = window.heat_map_score_ids || [];
  }

  heatMap(e) {
    const persona_value = e.srcElement.dataset["personaValue"];
    if (persona_value == null) {
      this.toggleAllHeatmaps(e.srcElement);
    } else {
      this.toggleOneHeatmap(persona_value, e.srcElement);
    }
    this.renderHeatMap();
  }

  toggleAllHeatmaps(allToggle) {
    const checked = $(allToggle).prop("checked");
    if (checked) {
      this.checkHeatmapToggle(allToggle);
      this.heatmapScoreTargets.forEach(e => {
        this.checkHeatmapToggle(e);
      });
    } else {
      this.uncheckHeatmapToggle(allToggle);
      this.heatmapScoreTargets.forEach(e => {
        this.uncheckHeatmapToggle(e);
      });
    }
  }

  toggleOneHeatmap(persona_id, heatmapToggle) {
    const checked = $(heatmapToggle).prop("checked");
    if (checked) {
      this.checkHeatmapToggle(heatmapToggle);
    } else {
      this.uncheckHeatmapToggle(heatmapToggle);
    }

    this.checkOrUncheckTopHeatmapToggle();
  }

  checkOrUncheckTopHeatmapToggle(excludedPersonaId = null) {
    const targets =
      excludedPersonaId == null
        ? this.heatmapScoreTargets
        : this.heatmapScoreTargets.filter(e => e.dataset["personaValue"] != excludedPersonaId);
    const allTogglesChecked =
      this.heat_map_selected_ids.length > 0 && this.heat_map_selected_ids.length === targets.length - 1;
    const allToggle = targets.find(e => e.dataset["personaValue"] == null);
    if (allTogglesChecked) {
      this.checkHeatmapToggle(allToggle);
    } else {
      this.uncheckHeatmapToggle(allToggle);
    }
  }

  checkHeatmapToggle(toggle) {
    $(toggle.parentElement).addClass("checked");
    $(toggle).prop("checked", true);
    const personaId = toggle.dataset["personaValue"];
    if (personaId != null) {
      this.heat_map_selected_ids.push(personaId);
    }
  }

  uncheckHeatmapToggle(toggle) {
    $(toggle.parentElement).removeClass("checked");
    $(toggle).prop("checked", false);
    this.removeFromHeatmapSelectedIds(toggle.dataset["personaValue"]);
  }

  renderHeatMap() {
    if (this.heat_map_selected_ids.length === 0) {
      window.dispatchEvent(
        new CustomEvent("adquick:audiences:heatmapturnedoff", {
          detail: { scorePersonaIds: this.heat_map_selected_ids },
        }),
      );
    } else {
      window.dispatchEvent(
        new CustomEvent("adquick:audiences:heatmapturnedon", {
          detail: { scorePersonaIds: this.heat_map_selected_ids, token: this.token() },
        }),
      );
    }
  }

  loadColorRange(h3_scores) {
    const colorRange = [
      [255, 251, 161, 255],
      [255, 184, 38, 255],
      [252, 130, 52, 255],
      [239, 31, 31, 255],
      [155, 0, 29, 155],
    ];
    this.colorRange = colorRange;

    this.weights = h3_scores.map(d => d.score);
    this.edges = [];
    this.size = colorRange.length;

    for (let i = 1; i < this.size; i++) {
      this.edges.push(this.weights[i * Math.floor(this.weights.length / this.size)]);
    }

    return weight => {
      for (let i = this.size - 1; i >= 0; i--) {
        if (weight >= this.edges[i - 1]) return colorRange[i];
      }
      return colorRange[0];
    };
  }

  h3Layer(h3_scores, colorCallback) {
    return new deck.MapboxLayer({
      id: "h3_scores",
      data: h3_scores,
      pickable: true,
      wireframe: false,
      highPrecision: true,
      filled: true,
      extruded: false,
      elevationScale: 1,
      coverage: 1,
      getHexagon: d => d.h3_6,
      getElevation: d => d.score,
      getFillColor: d => colorCallback(d.score),
      type: deck.H3HexagonLayer,
    });
  }

  get score_personas() {
    return JSON.parse(document.querySelector("#persona-data input").value);
  }

  connect() {
    StimulusReflex.register(this);
    this.saveScore = debounce(this.saveScore.bind(this), 500);
    this.filter = debounce(this.filter.bind(this), 500);
  }

  create(event) {
    const id = event.currentTarget.dataset.audience;
    this.stimulate(`${this.reflexClass}#create`, { id, heat_map_selected_ids: this.heat_map_selected_ids });
    window.dispatchEvent(new CustomEvent("adquick:audiences:audienceadded", { detail: { scorePersonaId: id } }));
  }

  destroy(event) {
    let id = event.currentTarget.dataset.persona;
    let reflex = `${this.reflexClass}#destroy`;

    if (!id) {
      id = event.currentTarget.dataset.audience;
      reflex = `${this.reflexClass}#destroy_by_audience_id`;
    }

    this.stimulate(reflex, { id, heat_map_selected_ids: this.heat_map_selected_ids });
    this.mapLoading();
    this.addLoadingById(id);

    this.removeFromHeatmapSelectedIds(id);
    this.checkOrUncheckTopHeatmapToggle(id);
    this.renderHeatMap();

    window.dispatchEvent(new CustomEvent("adquick:audiences:audienceremoved", { detail: { scorePersonaId: id } }));
  }

  addToNotificationList(event) {
    let id = event.currentTarget.dataset.persona;
    this.stimulate(`${this.reflexClass}#add_to_notification_list`, {
      id: id,
      heat_map_selected_ids: this.heat_map_selected_ids,
    });
  }

  removeFromNotificationList(event) {
    let id = event.currentTarget.dataset.persona;
    this.stimulate(`${this.reflexClass}#remove_from_notification_list`, {
      id: id,
      heat_map_selected_ids: this.heat_map_selected_ids,
    });
  }

  toggleTaxonomy(event) {
    if (this.taxonomyOpen === event.currentTarget.dataset.item) {
      this.closeTaxonomies();
      return;
    }
    this.closeTaxonomies();
    this.openTaxonomy(event.currentTarget.dataset.item);
    event.currentTarget.dataset.emptyCategories
      .split(",")
      .filter(category => category.length > 0)
      .forEach(category => {
        this.openCategory(category);
      });
  }

  toggleCategory(event) {
    if (this.categoryOpen === event.currentTarget.dataset.item) {
      this.closeCategories();
      return;
    }
    this.closeCategories();
    this.openCategory(event.currentTarget.dataset.item);
  }

  displayDescription(event) {
    this.stimulate(`${this.reflexClass}#display_description`, { audience_id: event.currentTarget.dataset.audienceId });
  }

  displayAdded() {
    const queryString = QueryString.parse(window.location.search, { parseBooleans: true });
    const token = this.token();
    this.stimulate(`${this.reflexClass}#display_added`, {
      plan_token: token,
      heat_map_selected: this.heat_map_selected_ids,
      filter_enabled: queryString.audiences,
    });
  }

  cleanActiveAudiences() {
    document.querySelectorAll(".audience_item.active").forEach(audience => {
      audience.classList.remove("active");
    });
  }

  openCategory(categoryName) {
    const item = this.categoryItemTargets.find(item => item.dataset.item === categoryName);
    item.classList.add("open");
    this.categoryOpen = categoryName;
  }

  openTaxonomy(taxonomyName) {
    const item = this.taxonomyItemTargets.find(item => item.dataset.item === taxonomyName);
    item.classList.add("open");
    this.taxonomyOpen = taxonomyName;
  }

  openAll() {
    const open = item => item.classList.add("open");
    this.taxonomyItemTargets.forEach(open);
    this.categoryItemTargets.forEach(open);
  }

  closeAll() {
    const close = item => item.classList.remove("open");
    this.taxonomyItemTargets.forEach(close);
    this.categoryItemTargets.forEach(close);
  }

  changeScore(event) {
    const value = event.currentTarget.value;
    const target = this.scoreTargets.find(t => t.dataset.audience === event.currentTarget.dataset.audience);
    target.textContent = Math.round(value);

    // NOTE: this is here to allow the user to see the score change in real time without sending the event to the backend. If this behavior becomes the new default you can just delete the rest of this function.
    if ("saveOnMouseup" in event.currentTarget.dataset) {
      return;
    }

    this.saveScore(target.dataset.audience, value);
  }

  // NOTE: this is supposed to be used to save the score when the user stops dragging the slider. If you want to save the score in real time you can use the default behavior of changeScore
  saveSelectedScore(event) {
    console.debug("saveSelectedScore event: ", event);
    const value = event.currentTarget.value;
    const target = this.scoreTargets.find(t => t.dataset.audience === event.currentTarget.dataset.audience);

    this.saveScore(target.dataset.audience, value);
  }

  removeLoading(event) {
    if (!event?.detail?.selector?.trim().startsWith(this.containerSelector)) return;

    this.personaTargets.forEach(persona => persona.classList.remove(loadingClass));
    document.removeEventListener("cable-ready:after-morph", window.removeAudienceLoadingListener);
  }

  addLoadingById(id) {
    const persona = this.personaTargets.find(persona => persona.dataset.audience === id);

    if (persona) {
      persona.classList.add(loadingClass);
      document.addEventListener("cable-ready:after-morph", window.removeAudienceLoadingListener);
    }
  }

  async saveScore(id, score) {
    this.addLoadingById(id);
    this.mapLoading();
    await this.stimulate(`${this.reflexClass}#change_score`, { id, score });
    window.dispatchEvent(new CustomEvent("adquick:audiences:scorechanged", { detail: { scorePersonaId: id, score } }));
  }

  closeTaxonomies() {
    this.taxonomyItemTargets.forEach(item => item.classList.remove("open"));
    this.taxonomyOpen = null;
  }

  closeCategories() {
    this.categoryItemTargets.forEach(item => item.classList.remove("open"));
    this.categoryOpen = null;
  }

  /**
   * toggleClass is a function that can be used to add or remove a class
   * from one or more HTML elements.
   *
   * @param {object} event - An event object that contains any data attributes.
   *
   * @data {string} toggleClass - The name of the class to be toggled.
   *
   * @data {string} toggleTarget - The CSS selector used to identify which
   element(s) should have its class toggled. If none is provided, toggleClass
   will be called on the element that dispatched the event.
   *
   * @returns - The function does not return any values.
   */
  toggleClass(event) {
    const e = event.currentTarget;
    const toggleClass = e.dataset["toggleClass"];
    const targetSelector = e.dataset["toggleTarget"];
    const toggleTarget = document.querySelectorAll(targetSelector || e);
    toggleTarget.forEach(element => {
      $(element).toggleClass(toggleClass);
    });
  }

  triggerSearchFromKbd(event) {
    console.log(event);
    if (event.keyCode == 13) {
      const targetSearch = event.currentTarget.dataset["searchTarget"];
      document.getElementById(targetSearch).click();
    }
  }

  async filter(event) {
    const source = this.queryTarget;
    if (this.is_filtering || (source.value.length < 3 && source.value.length > 0)) return;
    this.filtering = source.value;
    this.is_filtering = true;
    this.queryContainerTarget.classList.add(loadingClass);
    this.showItemLoading();
    // NOTE: couldn't figure out why morph is not replacing the children but this is the best I could come up with so far
    this.audienceItemTargets.forEach(item => item.remove());
    await this.stimulate(`${this.reflexClass}#search`, {
      query: this.filtering,
      provider_id: (this.providerTarget || {}).value,
      sort_by: (this.sortByTarget || {}).value,
    });
    this.is_filtering = false;
    this.hideItemLoading();
    this.mixpanel.track("Searched Audience Keyword", { Audience: this.filtering });
  }

  async filtersChanged(event) {
    const source = this.queryTarget;
    this.filtering = source.value;
    if (this.filtering.length < 3) return;
    event.target.value = event.detail.id;
    this.afterChangeFilter();
  }

  async afterChangeFilter() {
    const source = this.queryTarget;
    this.filtering = source.value;
    this.is_filtering = true;
    this.queryContainerTarget.classList.add(loadingClass);
    this.showItemLoading();
    this.audienceItemTargets.forEach(item => item.remove());
    await this.stimulate(`${this.reflexClass}#search`, {
      query: this.filtering,
      provider_id: (this.providerTarget || {}).value,
      sort_by: (this.sortByTarget || {}).value,
    });
    this.is_filtering = false;
    this.hideItemLoading();
  }

  unfilter() {
    this.stimulate(`${this.reflexClass}#search`, { query: "" });
  }

  searchError(element) {
    this.stimulate(`${this.reflexClass}#morph_search_error`, {
      query: this.filtering,
      provider_id: (this.providerTarget || {}).value,
      sort_by: (this.sortByTarget || {}).value,
    });
  }

  showItemLoading() {
    this.loadingItemTargets.forEach(node => {
      node.classList.add("show");
      node.classList.remove("hide");
    });
    this.taxonomyItemTargets.forEach(node => node.classList.add("hide"));
  }

  hideItemLoading() {
    this.loadingItemTargets.forEach(node => {
      node.classList.add("hide");
      node.classList.remove("show");
    });
  }

  clear_filter() {
    this.unfilter();
    this.queryTarget.value = "";
    if (this.providerTarget) this.providerTarget.value = "";
    if (this.sortByTarget) this.sortByTarget.value = "";
  }

  async newChangeAudienceMode(event) {
    const token = this.token();
    const use_or = event.currentTarget.classList.contains("toggle_switch_right");
    await this.stimulate(`${this.reflexClass}#change_audience_mode`, { use_or, plan_token: token });
    window.dispatchEvent(
      new CustomEvent("adquick:audiences:audiencemodetoggled", { detail: { audiencesUseOr: use_or } }),
    );
    this.mapLoading();
  }

  newToggleFilterOnOff(event) {
    const filterEnabled = event.currentTarget.classList.contains("toggle_switch_right");
    window.dispatchEvent(
      new CustomEvent("adquick:audiences:audiencefiltertoggled", { detail: { audienceFilterEnabled: filterEnabled } }),
    );
  }

  async changeAudienceMode(event) {
    const token = this.token();
    const use_or = event.currentTarget.checked;
    this.#setToggleColor(event);
    await this.stimulate(`${this.reflexClass}#change_audience_mode`, { use_or, plan_token: token });
    window.dispatchEvent(
      new CustomEvent("adquick:audiences:audiencemodetoggled", { detail: { audiencesUseOr: use_or } }),
    );
    this.mapLoading();
  }

  toggleFilterOnOff(event) {
    const filterEnabled = event.currentTarget.checked;
    this.#setToggleColor(event);
    window.dispatchEvent(
      new CustomEvent("adquick:audiences:audiencefiltertoggled", { detail: { audienceFilterEnabled: filterEnabled } }),
    );
  }

  #setToggleColor(event) {
    const on = event.currentTarget.checked;
    const element = event.currentTarget.parentElement.parentElement;
    const left = element.querySelector(".left_audience_toggle");
    const right = element.querySelector(".right_audience_toggle");
    const blue = " var(--blue)";
    if (on) {
      left.style.color = "";
      right.style.color = blue;
    } else {
      left.style.color = blue;
      right.style.color = "";
    }
  }

  mapLoading() {
    const selector = this.containerSelector ? `${this.containerSelector} #audience-data` : "#audience-data";
    if (window.map?.waitReflexLoading) {
      window.map.waitReflexLoading("cable-ready:after-morph", selector);
    }
  }

  token() {
    this.setToken();
    return this.plan_token;
  }
  setToken() {
    if (!this.plan_token) {
      this.plan_token = document.querySelector("#token-data > input")?.value;
    }
  }

  removeFromHeatmapSelectedIds(id) {
    const i = this.heat_map_selected_ids.indexOf(id);
    if (i >= 0) {
      this.heat_map_selected_ids.splice(i, 1);
    }
  }
}
