class ApiError extends Error {
  constructor(response) {
    super();
    this.response = response;
    this.name = 'ApiError';
  }
}

// Throw errors if bad request
// to handle them in catch
export const handleResponse = function (response) {
  if (!response.ok) {
    return response.json()
      .then(data => { throw new ApiError(data) })
      .catch(error => { throw error })
  }

  if (response.status === 204) return {};

  return response.json();
};

export const formatErrors = function (data) {
  let errors = data.errors || [];

  if (data.error) {
    errors.push(data.error);
  } else if (data.message) {
    errors.push(data.message);
  }

  return errors.join(', ');
};
