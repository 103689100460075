import RfpGridConfig from "./rfp_grid_config";

export default class ManagedVendorsLimitedGridConfig extends RfpGridConfig {
  checkAllowEmptyForLatLon() {
    return !this.config.supplier_has_dupes;
  }

  gridColumns() {
    const autocomplete = _.debounce(async (query, process) => {
      try {
        const encodedQuery = encodeURIComponent(query);
        const headers = { "Content-Type": "application/json" };
        const response = await fetch(
          `/api/v1/supplier_tasks/${this.config.supplier_task_id}/autocomplete_face_id?q=${encodedQuery}`,
          { headers },
        );
        const { data } = await response.json();
        process(data);
      } catch (error) {
        console.log(error);
      }
    }, 300);

    // Don't add front-end validations, they were intentionally removed here: https://github.com/adquick/adquick/pull/6740
    let columns = [
      {
        key: "supplier_face_id",
        title: "Face ID",
        read_only: false,
        type: "autocomplete",
        source: autocomplete,
        allowEmpty: true,
      },
      { key: "tab_panel_id", title: "Geopath ID", read_only: false, type: "text", allowEmpty: true },
      { key: "start_date", title: "Start date", read_only: false, type: "text", allowEmpty: true },
      { key: "end_date", title: "End date", read_only: false, type: "text", allowEmpty: true },
      { key: "lat", title: "Latitude", read_only: false, type: "numeric", allowEmpty: true },
      { key: "lon", title: "Longitude", read_only: false, type: "numeric", allowEmpty: true },
    ];

    if (this.showPriceColumn()) {
      if (this.isShortFlight()) {
        columns.push(this.priceForDurationColumn())
      } else {
        columns.push(this.priceColumn())
      }
    }

    if (this.isHold() || this.isInitial()) {
      columns.splice(1, 0, this.marketColumn());
    }

    if (!this.isRfp()) {
      columns.push(this.supplierStatusColumn());
    }

    if (this.isHold()) {
      columns.push(this.holdExpiresAtColumn())
      columns.push(this.replacementForColumn())
    }

    if (this.showBillingCycle()) {
      columns.push(this.billingCycleColumn());
    }

    if (this.allowBonusValue()) {
      columns = columns.concat(this.bonusValueColumns());
    }

    if (!this.config.supplier_has_dupes) {
      columns = columns.filter((c) => (!['lat', 'lon'].includes(c.key)));
    }

    window.gridColumns = columns.map((c) => c.key);
    return columns;
  }

  configureColumnsWidth(_width, col) {
    if (col == 0 || col == 1) {
      return 100;
    }
    return 50;
  }

  sampleRow() {
    return {
      supplier_face_id: "EXAMPLE-001",
      start_date: "1/1/2019",
      end_date: "12/31/2019",
      next_booked_date: "2/2/2020",
      lat: 30.7607,
      lon: -53.9633,
      price: 2500,
    };
  }

  showPriceColumn() {
    return this.config.hide_supplier_price === false;
  }

  priceColumn() {
    return { key: "price", title: this.priceColumnName(), read_only: false, type: "numeric", allowEmpty: true };
  }
}
