import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.loadData();
  }

  async loadData() {
    const res = await $.get(`/companies/${this.data.get("slug")}/map_points`);
    this.createMap(res.points);
  }

  createMap(points) {
    this.map = new deck.DeckGL({
      container: "map_container",
      mapboxApiAccessToken: window.AppConfig.mapboxAccessToken,
      mapStyle: "mapbox://styles/fahimf/cjy0g4rbh0y1d1cledi9pf3nk",
      controller: true,
      initialViewState: {
        longitude: -101.45,
        latitude: 40.5,
        zoom: 3.5,
        minZoom: 3,
        maxZoom: 4,
        scrollZoom: false,
        touchZoom: false,
      },
      layers: [
        new deck.ScatterplotLayer({
          data: points.map(coords => {
            return {
              position: coords,
              radius: 9000,
            };
          }),
          getColor: d => d.color,
          getRadius: d => d.radius,
          getFillColor: [145, 24, 132],
          getLineColor: [255, 255, 255],
        }),
      ],
    });

    if (this.map.scrollWheelZoom) {
      this.map.scrollWheelZoom.disable();
    }
  }
}
