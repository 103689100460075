import { Controller } from "stimulus";
import StimulusReflex from "stimulus_reflex";
import { get } from "../../../../../adquick-classic/src/marketplace/utils/api";

export default class extends Controller {
  static targets = ["label", "fileInput", "names"];

  connect() {
    StimulusReflex.register(this);
  }

  connected() {
    const cssDropClass = "bg-blue";

    this.labelTarget.addEventListener("dragenter", _e => this.labelTarget.classList.add(cssDropClass));
    this.labelTarget.addEventListener("drop", _e => this.labelTarget.classList.remove(cssDropClass));
    this.labelTarget.addEventListener("dragleave", e => {
      if (!this.labelTarget.contains(e.relatedTarget)) {
        this.labelTarget.classList.remove(cssDropClass);
      }
    });
  }

  uploading = false;

  async handleFileUpload() {
    const inputName = this.name;

    this.showLoading();

    for (const [i, file] of Array.from(this.fileInputTarget.files).entries()) {
      await this.upload(file);
      this.showUploadedFileName(file.name);
    }

    this.removeLoading();
  }

  showLoading() {
    this.uploading = true;
    this.labelTarget.classList.add("show-loading");
  }

  removeLoading() {
    this.uploading = false;
    this.labelTarget.classList.remove("show-loading");
  }

  async upload(file) {
    const params = {
      objectName: file.name,
      contentType: file.type,
      time: Date.now(),
      amzAcl: "private",
      directory: "user_uploads",
    };
    const headers = new Headers({ "Content-Type": file.type, "x-amz-acl": "private" });
    const url = "/s3/sign";
    const urlParams = new URLSearchParams(params);
    const signResponse = await get(`${url}?${urlParams.toString()}`);
    await fetch(signResponse.signedUrl, { body: file, headers: headers, method: "PUT" });

    await this.stimulate("Sketcher::Reflex#add_uploaded_file", {
      file_type: file.type,
      file_name: file.name,
      file_size: file.size,
      file_path: signResponse.path,
    });
  }

  showUploadedFileName(fileName) {
    const span = document.createElement("span");
    span.classList.add("filename");
    span.innerHTML = fileName;
    this.namesTarget.appendChild(span);
  }
}
