import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["grid", "pages"];

  get currentPage() {
    return this._currentPage;
  }

  set currentPage(page) {
    this._currentPage = Number.parseInt(page);
  }

  get lastPage() {
    return this._lastPage;
  }

  connect() {
    this._currentPage = Number.parseInt(this.data.get("currentPage"));
    this._lastPage = Number.parseInt(this.data.get("totalPages"));
    this.toggleActiveFromPagination();
  }

  fetchData(page) {
    const url = `${this.data.get("url")}?page=${page}`;
    fetch(url)
      .then(response => response.text())
      .then(html => {
        this.currentPage = page;
        this.gridTarget.innerHTML = html;
        this.toggleActiveFromPagination();
      });
  }

  goToPage(e) {
    const page = Number.parseInt(e.target.innerText);
    this.toggleActiveFromPagination();
    this.fetchData(page);
  }

  previousPage(e) {
    const previousPage = this.currentPage - 1;
    if (previousPage == 0) {
      return;
    }
    this.toggleActiveFromPagination();
    this.fetchData(previousPage);
  }

  nextPage(e) {
    if (this.lastPage == 1) {
      return;
    }
    const nextPage = this.currentPage + 1;
    if (nextPage > this.lastPage) {
      return;
    }
    this.toggleActiveFromPagination();
    this.fetchData(nextPage);
  }

  toggleActiveFromPagination() {
    const index = this.currentPage - 1;
    this.pagesTarget.children[index].classList.toggle("active");
  }
}
