import ApplicationController from "../../../plus/src/controllers/application_controller";

// The purpose of this controller is to disable the form submission if
// any of the fields marked with `required` attribute are empty.
// This uses the native html validation api https://developer.mozilla.org/en-US/docs/Learn/Forms/Form_validation
// As of now it will only check for the `required` attribute but can be extended to check for other attributes as well.
export class DisableSubmitValidationController extends ApplicationController {
  validate() {
    const form = this.element;
    const requiredFields = form.querySelectorAll("[required]");
    const submitButton = this.element.querySelector('input[type="submit"], button[type="submit"]');
    if (!submitButton) {
      console.error("No submit button found in the form");
      return;
    }
    let isFormValid = true;
    requiredFields.forEach(field => {
      if (field.validity.valueMissing) {
        isFormValid = false;
      }
    });

    if (isFormValid) {
      submitButton.removeAttribute("disabled");
    } else {
      submitButton.setAttribute("disabled", "disabled");
    }
  }
}
