import ApplicationController from "../application_controller";
import { delay } from "lodash";

export default class extends ApplicationController {
  static values = {
    sketchToken: String,
    jobId: String,
    sessionId: String,
  };

  connected() {
    const delayTime = this.isActionCableConnectionOpen() ? 0 : 2000;
    delay(this.checkJobStatus.bind(this), delayTime);
  }

  checkJobStatus() {
    const jobIdPresent = this.jobIdValue != null && this.jobIdValue !== "";
    if (jobIdPresent) {
      this.stimulate("UI::BackgroundJobProgressReflex#wait_for_job", {
        job_id: this.jobIdValue,
        stop_on_first_failure: false,
        timeout_seconds: 60,
      });
    }
  }

  waitForJobSuccess(e) {
    let searchParams;
    if (this.sessionIdValue) {
      searchParams = new URLSearchParams(window.location.search);
      searchParams.set("session_id", this.sessionIdValue);
    }

    if (window.location.pathname == "/browse") {
      if (searchParams) {
        window.location.search = searchParams.toString();
        return;
      }

      window.location.reload();
      return;
    }

    if (window.location.pathname == "/billboard-locations") {
      if (searchParams) {
        window.location.search = searchParams.toString();
        return;
      }

      window.location.reload();
      return;
    }

    if (window.location.pathname.includes("ooh-planner")) {
      window.location.href = `/ooh-planner/${this.sketchTokenValue}`;
    } else {
      window.location.href = `/planner/${this.sketchTokenValue}`;
    }
  }
}
