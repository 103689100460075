export default class RfpGridConfig {
  constructor(config) {
    this._config = config;
    this._managed_or_unmanaged = config.managed_or_unmanaged;
    this._readonly = false;
    this._advertiser_id = config.advertiser_id;
    this._agency_id = config.agency_id;
    this._carvana_id = 16601;
    this._talon_id = 123;
    this._quan_id = 7;
  }

  get config() {
    return this._config;
  }

  get errorMessages() {
    return {
      required: "is required.",
      required_min_length: "must have a min length of 4 characters.",
      numeric: "must be a number (E.g.: 1000, 1123.53).",
      numeric_required: "is required and must be a number (E.g.: 1000, 1123.5).",
      unit_type:
        "is required and must be one of: billboard, street furniture, wallscape, wildposting, alternative, transit",
      unit_subtype: "select value from list",
      screen_subtype: "select value from list",
      screen_type: "is required and must be one of: static or digital",
      url:
        "is optional and must be a valid URL (E.g: http://my-domain.com/image.jpeg, https://my-domain.com/image.jpeg",
      boolean: "is optional and must be Y or N",
      boolean_required: "is required and must be Y or N",
      supplier_status: this.supplierStatusErrorMessage(),
      latitude: "is required, has to be a number between 23 and 53",
      longitude: "is required, has to be a number between -128 and 35",
      name: "is required, check if the submited value is too small",
      size: "is required, check if the submited value is too big",
      impressions: "is required, has to  be a number, values smaller than 999 are considered suspected",
      installation_cost: "is required, has to be a number, values bigger than 999.999 are considered suspected",
      digital_required: "is required for digital units and it needs to be a number bigger than 0",
    };
  }

  isManaged() {
    return this._managed_or_unmanaged === "managed";
  }

  isUnmanaged() {
    return this._managed_or_unmanaged === "unmanaged";
  }

  readonly() {
    return this._readonly;
  }

  advertiserId() {
    return this._advertiser_id;
  }

  agencyId() {
    return this._agency_id;
  }

  allowEmptyOrientation() {
    return this.advertiserId() !== this._carvana_id;
  }

  allowEmptyNextBookedDate() {
    return this.advertiserId() !== this._carvana_id;
  }

  allowEmptyImpressions() {
    return this.advertiserId() !== this._carvana_id;
  }

  allowForcedProductionEmpty() {
    return this.agencyId() !== this._talon_id;
  }

  allowCancellationPolicyEmpty() {
    return this.agencyId() !== this._talon_id;
  }

  allowDesignAssetDueDateEmpty() {
    return this.agencyId() !== this._talon_id;
  }

  allowArtworkPreApprovalEmpty() {
    return this.agencyId() !== this._talon_id;
  }

  showBillingCycle() {
    return this.agencyId() == this._talon_id;
  }

  priceColumnName() {
    if (this.agencyId() != this._talon_id) {
      return "4 Week Price";
    }
    return "Cost per cycle";
  }

  priceForDurationColumn() {
    return {
      key: 'price_for_duration',
      title: 'Price for duration',
      read_only: false,
      type: 'numeric',
      allowEmpty: true // this will be validated in the backend as the validation is complex
    }
  }

  allowBonusValue() {
    return this.agencyId() == this._quan_id;
  }

  supplierStatusErrorMessage() {
    if (this.isInitial()) {
      return "is required and must be available or not available";
    }
    return "is required and must be Hold (Primary) or Hold (Backup) or Unavailable";
  }

  supplierStatusColumnOptions() {
    if (this.isInitial()) {
      return ["Available", "Not available"];
    }
    return ["Hold (Primary)", "Hold (Backup)", "Unavailable"];
  }

  supplierStatusColumn() {
    return {
      key: "supplier_status",
      title: "Status",
      read_only: false,
      type: "dropdown",
      source: this.supplierStatusColumnOptions(),
      allowEmpty: false,
      validator: this.supplierStatusValidator.bind(this),
      errorMessage: this.errorMessages.supplier_status,
    };
  }

  holdExpiresAtColumn() {
    return {
      key: "hold_expires_at",
      title: "Hold Expires At",
      read_only: false,
      type: "text",
      validator: this.checkAllowEmpty,
      errorMessage: this.errorMessages.required,
      allowEmpty: true,
    };
  }

  replacementForColumn() {
    return {
      key: "replacement_for",
      title: "Replacement For",
      read_only: true,
      type: "text",
    };
  }

  previousAdvertisers() {
    return {
      key: "previous_advertisers",
      title: "Previous Advertisers",
      read_only: false,
      type: "text",
      allowEmpty: true,
    };
  }

  marketColumn() {
    return {
      key: "market",
      title: "Market",
      read_only: true,
      type: "text",
      allowEmpty: true,
    };
  }

  billingCycleColumn() {
    return {
      key: "billing_cycle",
      title: "Cycle type",
      read_only: false,
      type: "dropdown",
      source: ["4_week", "monthly"],
      allowEmpty: false,
      errorMessage: 'Select one of "4_week", "monthly"',
    };
  }

  bonusValueColumns() {
    return [
      { key: "bonus", title: "Bonus", read_only: this.readonly(), type: "text", allowEmpty: true },
      { key: "bonus_value", title: "Bonus Value", read_only: false, type: "text", allowEmpty: true },
    ];
  }

  gridColumns() {
    return [];
  }

  findGridColumnByKey(key) {
    return this.gridColumns().find(c => c.key == key);
  }

  findColumnIndexByKey(key) {
    return this.gridColumns().findIndex(c => c.key == key);
  }

  sampleRow() {
    return {};
  }

  configureColumnsWidth() {
    return;
  }

  errorMessage(column) {
    if (!column || !column.errorMessage) return "";
    return `${column.title} ${column.errorMessage}`;
  }

  checkAllowEmpty(value, callback) {
    if (!this.allowEmpty && (value == null || value === "")) {
      return callback(false);
    }
    callback(true);
  }

  checkMinLength(value, callback) {
    if (this.allowEmpty || (value && value.length >= 4)) {
      return callback(true);
    }
    callback(false);
  }

  validateImpression(value, callback) {
    if (this.allowEmpty || Number.parseInt(value) >= 999) {
      return callback(true);
    }
    callback(false);
  }

  unitTypeValidator(config) {
    return function(value, callback) {
      const unit_type_map = config.unit_type_map;
      value = _.snakeCase(value);
      if (value.includes("bulletin") || unit_type_map[value]) {
        callback(true);
      } else {
        if (window.Rollbar && value.lenght > 2) Rollbar.warning(`Unknown unit type: ${value}.`);
        callback(false);
      }
    };
  }

  supplierStatusValidator(value, callback) {
    const isInvalid = value == null || value === "" || !this.isStatusStringValid(value);
    if (isInvalid) {
      return callback(false);
    }
    callback(true);
  }

  isStatusStringValid(value) {
    if (this.isInitial()) {
      return value && (value.toLowerCase() == "available" || value.toLowerCase() == "not available");
    }
    return (
      value &&
      (value.toLowerCase() == "hold (primary)" ||
        value.toLowerCase() == "hold (backup)" ||
        value.toLowerCase() == "unavailable")
    );
  }

  checkUrl(value, callback) {
    if (!_.isEmpty(value)) {
      try {
        new URL(value);
      } catch (error) {
        return callback(false);
      }
    }
    return callback(true);
  }

  checkBoolean(value, callback) {
    if (!_.isEmpty(value)) {
      const lowerValue = value.toLowerCase();
      if (lowerValue != "y" && lowerValue != "n") {
        return callback(false);
      }
      return callback(true);
    }
    return callback(true);
  }

  isRfp() {
    return this.config.supplier_task_type == "rfp";
  }

  isInitial() {
    return this.config.supplier_task_type == "initial";
  }

  isHold() {
    return this.config.supplier_task_type == "hold";
  }

  isShortFlight() {
    return this.config.short_flight;
  }
}
