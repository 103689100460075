import ApplicationController from "../../../plus/src/controllers/application_controller";

export class ScrollTo extends ApplicationController {
  connected() {
    this.element.addEventListener("click", this.#scroll)
  }

  disconnect() {
    this.element.removeEventListener("click", this.#scroll)
  }

  #scroll() {
    const targetElementId = this.getAttribute('href')
    const targetElement = document.querySelector(targetElementId)

    targetElement.scrollIntoView({ behavior: "smooth" });
  }
}
