import ApplicationController from "../application_controller";

export default class extends ApplicationController {
  static targets = ["mediaTypeRange", "errorMessage", "rangeTextInputToggler", "rangeTextInput", "totalMediaTypesSum"];

  sumAllWeights(elements) {
    return elements.reduce((acc, input) => {
      acc += Number.parseInt(input.value);
      return acc;
    }, 0);
  }

  validateWeightSum(elements) {
    const totalSum = this.sumAllWeights(elements);
    if (totalSum > 100) {
      return this.showError() && false;
    }
    return true;
  }

  findRangeInputContainer(inputName) {
    return this.rangeTextInputTogglerTargets.find(t => t.dataset.inputName == inputName);
  }

  showRangeTextInput(e) {
    const container = this.findRangeInputContainer(e.currentTarget.dataset.inputName);
    container.classList.add("show");
  }

  hideRangeTextInput(e) {
    const container = this.findRangeInputContainer(e.currentTarget.dataset.inputName);
    container.classList.remove("show");
  }

  showError() {
    this.errorMessageTarget.classList.remove("hide");
    this.errorMessageTarget.innerText = "*Sum of all have to be 100%";
  }

  showTotalSum() {
    let sumTargetToUpdate = this.totalMediaTypesSumTarget;
    let newSum = this.sumAllWeights(this.mediaTypeRangeTargets);
    sumTargetToUpdate.innerHTML = newSum + "%";
  }

  handleRangeChange(e) {
    var percentageToUpdate = this.findRangeInputContainer(e.currentTarget.name);
    percentageToUpdate.innerHTML = e.currentTarget.value + "%";
    this.showTotalSum();
    if (!this.validateWeightSum(this.mediaTypeRangeTargets)) {
      return;
    }
    this.stimulate("Sketcher::Reflex#saveAbbMediatype", e.currentTarget);
  }

  handleRangeTextInputChange(e) {
    if (!this.validateWeightSum(this.rangeTextInputTargets)) {
      return;
    }
    this.stimulate("Sketcher::Reflex#saveAbbMediatype", e.currentTarget);
    this.hideRangeTextInput(e);
  }
}
