import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["authenticateEmail", "authenticatePassword", "registerEmail", "registerPassword", "visitorEmail"];

  async post(url, payload) {
    this.saveUserEmailOnSessionStorage(payload);
    const api = await fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    });
    if (!api.ok) {
      const error = await api.text();
      throw new Error(error);
    }
    const data = await api.json();
    return data;
  }

  authenticatePayload() {
    const email = this.authenticateEmailTarget.value;
    const password = this.authenticatePasswordTarget.value;
    return { email, password };
  }

  registrationPayload() {
    const email = this.registerEmailTarget.value;
    const password = this.registerPasswordTarget.value;
    return { email, password };
  }

  getPlannerLinkFromSession() {
    return window.sessionStorage.getItem("planner_token");
  }

  saveUserEmailOnSessionStorage({ email }) {
    window.sessionStorage.setItem("visitor_email", email);
  }

  handleError(error) {
    const response = JSON.parse(error["message"]);
    const { message } = response;
    alert(message);
  }

  async authenticate() {
    try {
      const response = await this.post("/api/v2/users/sessions", this.authenticatePayload());
      await this.stimulate("Sketcher::Reflex#create_sketch_campaign", { user_id: response.user_id });
      window.modal.close();
    } catch (error) {
      this.handleError(error);
    }
  }

  async register() {
    try {
      await this.createOpportunity();
      const response = await this.post("/api/v2/users/registrations", this.registrationPayload());
      await this.stimulate("Sketcher::Reflex#create_sketch_campaign", { user_id: response.user_id });
      window.modal.close();
    } catch (error) {
      this.handleError(error);
    }
  }

  async visitorSubmit() {
    try {
      const email = this.visitorEmailTarget.value;
      if (email.length === 0) {
        return this.addErrorToEmailField();
      }
      await opportunity_flow(email, {
        product: "ooh-planner",
        referring_page: "ooh_planner_page",
        data: { planner_link: this.getPlannerLinkFromSession() },
      });
      await this.stimulate("Sketcher::Reflex#create_sketch_campaign", { visitor_email: email });
      this.completeOpportunity();
      window.modal.close();
    } catch (error) {
      this.handleError(error);
    }
  }

  addErrorToEmailField() {
    this.visitorEmailTarget.classList.add("input-error");
    this.visitorEmailTarget.placeholder = "Email is required";
  }

  async completeOpportunity() {
    const opportunity = getCookie("opportunity");
    if (!opportunity) {
      return;
    }
    await update_opportunity({ ...opportunity, status: "completed" });
  }

  async createOpportunity() {
    const { email } = this.registrationPayload();
    await opportunity_flow(email, {
      product: "ooh-planner",
      referring_page: "ooh_planner_page",
      data: { planner_link: this.getPlannerLinkFromSession() },
    });
  }

  fetchModalAndOpenCalendly() {
    this.loadSuccessModal(true);
    openCalendly();
  }

  loadSuccessModal(close = false) {
    if (close) {
      window.modal.close();
    }
    this.stimulate("ModalReflex#load_modal_component", { component: "modal/success_schedule_event" });
  }
}
