import ApplicationController from "../../../plus/src/controllers/application_controller";

export class OpenDialogController extends ApplicationController {
  static values = {
    dialogId: String
  }

  open() {
    document.getElementById(this.dialogIdValue).showModal()
  }
}
