import ApplicationController from "../application_controller";
import { currentMarketBudgetCardsState } from "./helpers";
import accounting from "accounting";

export default class extends ApplicationController {
  static targets = ["cardGrid", "geopathSummary", "recommendSpend", "toggleAll", "map"];

  connected() {
    this.loadMaps();
    this.setActiveRecommend();
  }

  expandCards() {
    if (this.cardGridTargets.length == 0) {
      return;
    }
    this.cardGridTargets.forEach(card => this.toggleState(card, "expanded"));
  }

  loadMaps() {
    this.mapTargets.forEach(el => this.initiateLocationMapForElement(el));
  }

  initiateLocationMapForElement(element) {
    const lat = element.dataset.lat;
    const lon = element.dataset.lon;
    mapboxgl.accessToken = "pk.eyJ1IjoiZmFoaW1mIiwiYSI6ImNpeTNhMXFmYTAwM3kyd21udWc4dzEzeWIifQ.-UQVnwzTeUjjOVjrBNgS1g";
    new mapboxgl.Map({
      container: element.id,
      style: "mapbox://styles/fahimf/cjy0g4rbh0y1d1cledi9pf3nk",
      center: [lon, lat],
      zoom: 8,
      interactive: true,
      attributionControl: false,
    });
  }

  getCardId(el) {
    return el.dataset.id;
  }

  getCurrentCardScope(card_id) {
    return this.cardGridTargets.find(el => el.dataset.id === card_id);
  }

  getCurrentGeopathScope(card_id) {
    return this.geopathSummaryTargets.find(el => el.dataset.id === card_id);
  }

  getCurrentRecommendedScope(card_id) {
    const scope = this.getCurrentCardScope(card_id);
    const elements = scope.querySelector(".buttons_grid").children;
    return Array.from(elements);
  }

  getFormattedBudget(budget) {
    return Number(accounting.formatNumber(budget, 2, ""));
  }

  setActiveRecommend() {
    this.cardGridTargets.forEach(card => this.toggleRecommendedButtonForMarket(card));
  }

  toggleRecommendedButtonForMarket(marketElement) {
    const card_id = marketElement.dataset.id;
    const budget = this.getFormattedBudget(marketElement.dataset.budget);
    const scope = this.getCurrentRecommendedScope(card_id);
    const recommendedBudgetKey = this.findActiveRecommendedSpendKey(scope, budget);
    const element = scope.find(t => t.dataset.level === recommendedBudgetKey);
    element.classList.toggle("white");
  }

  findActiveRecommendedSpendKey(scope, budget) {
    const recommendedValues = this.recommendedSpendObject(scope);
    return Object.keys(recommendedValues).find(level => {
      const entry = recommendedValues["entry"];
      const saturation = recommendedValues["saturation"];
      const domination = recommendedValues["domination"];
      if (level === "entry") {
        return budget <= entry || (budget >= entry && budget < saturation);
      }
      if (level === "saturation") {
        return (budget > entry && budget <= saturation) || (budget > saturation && budget < domination);
      }
      if (level === "domination") {
        return (budget > saturation && budget <= domination) || budget > domination;
      }
    });
  }

  recommendedSpendObject(scope) {
    return scope.reduce((acc, value) => {
      const level = value.dataset.level;
      const spend = Number.parseInt(value.dataset.spend);
      acc[level] = spend;
      return acc;
    }, {});
  }

  toggleCard(e) {
    const cardId = this.getCardId(e.currentTarget);
    const scope = this.getCurrentCardScope(cardId);
    this.toggleState(scope, "expanded");
  }

  useRecommendedSpend(e) {
    const payload = {
      market_id: e.currentTarget.dataset.marketId,
      budget: e.currentTarget.dataset.spend,
      market_budget_cards_state: currentMarketBudgetCardsState(this.cardGridTargets),
    };
    this.stimulate("Sketcher::BudgetReflex#updateRecomendedBudget", payload);
  }

  updateBudget(e) {
    const payload = {
      market_id: e.currentTarget.dataset.marketId,
      budget: e.currentTarget.value,
      market_budget_cards_state: currentMarketBudgetCardsState(this.cardGridTargets),
    };
    this.stimulate("Sketcher::BudgetReflex#updateBudget", payload);
  }

  updateImpressions(e) {
    const payload = {
      market_id: e.currentTarget.dataset.marketId,
      impressions: e.currentTarget.value,
      market_budget_cards_state: currentMarketBudgetCardsState(this.cardGridTargets),
    };
    this.stimulate("Sketcher::BudgetReflex#updateImpressions", payload);
  }

  toggleState(scope, state) {
    scope.classList.toggle(state);
  }

  toggleGeopathSummary(scope) {
    scope.classList.toggle("hide");
  }

  toggleAllGeopathSummary() {
    this.geopathSummaryTargets.forEach(el => this.toggleGeopathSummary(el));
  }

  toggleAllCards(e) {
    const buttonElement = e.currentTarget;
    const expand = buttonElement.classList.contains("expand-all");
    if (expand) {
      this.cardGridTargets.forEach(el => el.classList.add("expanded"));
      buttonElement.classList.remove("expand-all");
      buttonElement.querySelector("span").textContent = "Shrink All";
      return;
    }
    this.cardGridTargets.forEach(el => el.classList.remove("expanded"));
    buttonElement.classList.add("expand-all");
    buttonElement.querySelector("span").textContent = "Expand All";
  }
}
