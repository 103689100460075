export const currentMarketBudgetCardsState = elements => {
  return Array.from(elements).reduce((acc, card) => {
    const { dataset } = card;
    acc[dataset.id] = buildCardState(card);
    return acc;
  }, {});
};

const buildCardState = card => {
  const { nextElementSibling } = card;
  const geopathSummaryElement = nextElementSibling || defaultHiddenDiv();
  return {
    expanded: card.classList.contains("expanded"),
    geopath_summary: !geopathSummaryElement.classList.contains("hide"),
  };
};

const defaultHiddenDiv = () => {
  const div = document.createElement("div");
  div.classList.add("hide");
  return div;
};
