import ApplicationController from "../../../plus/src/controllers/application_controller";

export class AnimateStreamController extends ApplicationController {
  connected() {
    console.log(this.element.dataset);
    const newActions = `turbo:before-stream-render@document->animate-stream#call`
    this.element.setAttribute("data-action", newActions)
  }

  call(event) {
    if (event.target.action === "remove") {
      console.log("remove")
      return event.target.performAction()
    }
    console.log(event.target.templateElement.content.firstElementChild)
    event.target.templateElement.content.firstElementChild.classList.add(this.element.dataset.enterClass)
    console.log(event.target.templateElement.content.firstElementChild)
    return event.target.performAction()
    if (event.target.firstElementChild instanceof HTMLTemplateElement) {
      var enterAnimationClass = event.target.templateContent.firstElementChild.dataset.enterClass
      if (enterAnimationClass) {
        event.target.templateElement.content.firstElementChild.classList.add(enterAnimationClass)
      }
    }

    // Add a class to an element we are about to remove from the page
    // as defined by its "data-stream-exit-class"
    var elementToRemove = document.getElementById(event.target.target)
    if (elementToRemove) {
      var streamExitClass = elementToRemove.dataset.leaveClass
      if (streamExitClass) {
        // Intercept the removal of the element
        event.preventDefault()
        elementToRemove.classList.add(streamExitClass)
        // Wait for its animation to end before removing the element
        elementToRemove.addEventListener("animationend", function() {
          event.target.performAction()
        })
      }
    }
  }
}
