import ApplicationController from "../application_controller";
import { currentMarketBudgetCardsState } from "./helpers";

export default class extends ApplicationController {
  async addMarket(e) {
    const { currentTarget } = e;
    if (currentTarget.classList.contains("added")) {
      return;
    }
    await this.stimulate("Sketcher::MarketsReflex#addMarket", this.payload(currentTarget));
    currentTarget.classList.toggle("added");
  }

  removeMarket(e) {
    this.stimulate("Sketcher::MarketsReflex#removeMarket", this.payload(e.currentTarget));
  }

  payload(buttonTarget) {
    return {
      location_detail_id: buttonTarget.dataset.locationDetailId,
      dma_id: buttonTarget.dataset.dmaId,
      market_budget_cards_state: currentMarketBudgetCardsState(this.budgetCardElements()),
    };
  }

  budgetCardElements() {
    return document.querySelectorAll("[data-planner-market-budget-target='cardGrid']");
  }
}
