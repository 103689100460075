import ApplicationController from "./application_controller";
import StimulusReflex from "stimulus_reflex";
import tablesort from "tablesort";

export default class extends ApplicationController {
  static targets = ["message"];

  initialize() {
    if (document.getElementById("budget").value == "") {
      this.addTableSorting();
    }
  }

  async connect() {
    StimulusReflex.register(this);
  }

  open_modal(e) {
    e.preventDefault();
    this.stimulate("AudienceRecommender::ManualAccessReflex#open_modal");
    window.generic_modal.open();
  }

  search(e) {
    e.preventDefault();
    const searchAudience = document.getElementById("search-audience").value;
    this.stimulate("AudienceRecommender::ManualAccessReflex#search", { search: searchAudience });
  }

  manual_access(e) {
    e.preventDefault();
    const id = e.currentTarget.dataset.id;
    const name = e.currentTarget.dataset.name;
    this.stimulate("AudienceRecommender::ManualAccessReflex#access", { advertiser_id: id });
    window.generic_modal.close();
    this.stimulate(
      "UI::ToastReflex#show_title_body",
      "success",
      "Access shared successfully",
      `${name} has been given access to the Audience Market Recommender`,
    );
  }

  download_csv(e) {
    e.preventDefault();
    $("#download_csv").val("1");
    document.audience_recommender.submit();
  }

  get_result(e) {
    e.preventDefault();
    $("#download_csv").val("0");
    document.audience_recommender.submit();
  }

  addTableSorting() {
    function cleanNumber(i) {
      return i.replace(/[^\-?0-9.]/g, "");
    }

    function compareNumber(a, b) {
      a = parseFloat(a);
      b = parseFloat(b);

      a = isNaN(a) ? 0 : a;
      b = isNaN(b) ? 0 : b;

      return a - b;
    }

    tablesort.extend(
      "number",
      function(item) {
        return (
          item.match(/^-?[£\x24Û¢´€]?\d+\s*([,\.]\d{0,2})/) || // Prefixed currency
          item.match(/^-?\d+\s*([,\.]\d{0,2})?[£\x24Û¢´€]/) || // Suffixed currency
          item.match(/^-?(\d)*-?([,\.]){0,1}-?(\d)+([E,e][\-+][\d]+)?%?$/)
        ); // Number
      },
      function(a, b) {
        a = cleanNumber(a);
        b = cleanNumber(b);
        return compareNumber(b, a);
      },
    );
    $.find("table").forEach(tablesort);
  }
}
