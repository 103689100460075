import { Controller } from "stimulus";
import Quill from "quill";

export default class extends Controller {
  static targets = ["toolbar"];

  initialize() {
    this.defaults = {
      modules: {
        toolbar: [
          [{ size: ["small", false, "large", "huge"] }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ font: [] }],
          ["bold", "italic", "underline", "strike"],
          ["blockquote", "code-block"],
          ["link", "image"],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ script: "sub" }, { script: "super" }],
          [{ indent: "-1" }, { indent: "+1" }],
          [{ direction: "rtl" }],
          [{ color: [] }, { background: [] }],
          [{ align: [] }],
          ["clean"],
        ],
      },
      scrollingContainer: "#editor-wrapper",
      placeholder: "Compose something epic",
      theme: "snow",
    };
  }

  connect() {
    this.editor = new Quill(this.element, this.defaults);
    this.loadContent();
  }

  loadContent() {
    if (!this.element.dataset.content) {
      return;
    }
    this.editor.clipboard.dangerouslyPasteHTML(this.element.dataset.content, "api");
  }
}
