import { Controller } from "stimulus";
import StimulusReflex from "stimulus_reflex";

export default class extends Controller {
  connect() {
    StimulusReflex.register(this);
    this.connected();
  }

  connected() {
    // implement if you want
  }
}
