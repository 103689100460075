import { Controller } from "stimulus";

export class ExternalApiResultsShowController extends Controller {
  static values = {
    bodies: Array,
    url: String,
  };

  async retry() {
    // VERY ugly hack to disable the click while the request is running
    // since for now this only redirects to a new page with window.location it works, if we stop redirecting the user this have to change
    delete this.element.dataset.controller;
    const response = await fetch(this.urlValue, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ bodies: this.bodiesValue }),
    });
    if (response.status == 200) {
      const responseJson = await response.json();
      window.location.href = responseJson.result_path;
    }
  }
}
