import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["pageNavigation", "pageHeader", "errors", "advertiserUrlInput"];

  get navigationOffset() {
    return this._navigationOffset;
  }

  connected() {
    if (this.hasPageHeaderTarget) {
      this._navigationOffset = this.pageHeaderTarget.offsetTop;
    }
  }

  onScroll(e) {
    if (window.pageYOffset >= this.navigationOffset) {
      this.pageHeaderTarget.classList.add("sticky");
    } else {
      this.pageHeaderTarget.classList.remove("sticky");
    }
  }

  updateBudgetDates(e) {
    e.preventDefault();
    const dates = JSON.parse(e.currentTarget.dataset.value);
    if (dates.length < 2) {
      return;
    }
    this.stimulate("Sketcher::BudgetReflex#updateDates", e.currentTarget);
  }

  updatePeriod(e) {
    this.stimulate("Sketcher::BudgetReflex#updateDuration", e.currentTarget);
  }

  selectTeamMember(e) {
    const userId = e.target.dataset.id;
    const role = e.target.parentElement.parentElement.id.replace("-options", "");
    const name = e.target.dataset.name;

    this.stimulate("Sketcher::Reflex#update_campaign_strategy_user", { userId, role, name });
  }

  updateTeamMemberName(e) {
    const role = e.target.name;
    const name = e.target.value;

    this.stimulate("Sketcher::Reflex#update_campaign_strategy_user", { role, name });
  }

  toggleManualEntry(e) {
    const role = e.target.value;
    const manualEntry = e.target.checked;

    this.stimulate("Sketcher::Reflex#update_manual_entry", { role, manualEntry });
  }

  goToSection(e) {
    const sectionId = e.currentTarget.dataset.section;
    const sectionOffset = document.getElementById(sectionId).offsetTop;
    window.scrollTo({ top: sectionOffset - 100, behavior: "smooth" });
    this.#setActiveNavigationLink(sectionId);
  }

  #setActiveNavigationLink(sectionId) {
    this.pageNavigationTargets.forEach(e => e.classList.remove("active"));
    this.pageNavigationTargets.find(e => e.dataset.section == sectionId).classList.add("active");
  }

  toggleAdvertiserSelector(e) {
    const advertiserSelectWrapper = document.getElementById("js-advertiser-select-wrapper");
    const advertiserFreeTextWrapper = document.getElementById("js-advertiser-free-text-wrapper");
    document.querySelectorAll(".toggle_buttons button").forEach(e => e.classList.remove("active"));

    if (e.target.value === "free_text") {
      const advertiserName = advertiserFreeTextWrapper.querySelector("input").value;

      advertiserSelectWrapper.classList.add("hide");
      advertiserFreeTextWrapper.classList.remove("hide");

      this.stimulate("Sketcher::Reflex#set_advertiser", "advertiser_name", advertiserName);
    } else {
      const advertiserId = advertiserSelectWrapper.querySelector("#advertiser_id").value;

      advertiserFreeTextWrapper.classList.add("hide");
      advertiserSelectWrapper.classList.remove("hide");

      this.stimulate("Sketcher::Reflex#set_advertiser", "advertiser_id", advertiserId);
    }
    // Toggle buttons only, not needed for radio buttons
    if (e.target.nodeName == "BUTTON") {
      e.target.classList.add("active");
    }
  }

  setAdvertiser(e) {
    const advertiserField = e.target.name;
    const advertiserValue = e.target.value;

    this.stimulate("Sketcher::Reflex#set_advertiser", advertiserField, advertiserValue);
  }

  // TODO: Improve this to show a loading screen while the campaign gets created in the background
  async generateCampaign() {
    try {
      await this.stimulate("Sketcher::Reflex#create_sketch_campaign", { skip_morph_review: true });
      this.checkErrors();
    } catch (error) {
      console.log(error);
    }
  }

  async submitAiKickoff() {
    const isBrowse = this.element.dataset.isBrowse;
    if (this.checkForUrlError()) {
      await this.stimulate("Sketcher::Reflex#kick_off_ai", {
        is_browse: isBrowse,
        url: this.advertiserUrlInputTarget.value,
      });
    }
  }

  checkForUrlError() {
    const url = this.advertiserUrlInputTarget.value;

    if (!url || !this.urlPattern().test(url)) {
      alert("Please enter a valid url.");
      return false;
    } else {
      return true;
    }
  }

  checkErrors() {
    const errorMessages = Array.from(this.errorsTarget.children);
    if (errorMessages.length > 0) {
      throw "has errors";
    }
  }

  urlPattern() {
    return new RegExp('^(https?:\\/\\/)?'+ // validate protocol
	    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // validate domain name
	    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // validate OR ip (v4) address
	    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // validate port and path
	    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // validate query string
	    '(\\#[-a-z\\d_]*)?$','i');
  }
}
