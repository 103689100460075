import { Controller } from "stimulus";
import debounce from "lodash/debounce";
import StimulusReflex from "stimulus_reflex";

export default class extends Controller {
  static targets = ["options", "selected", "query", "control"];

  initialize() {
    this.search = debounce(this.search.bind(this), 500);
  }

  connect() {
    StimulusReflex.register(this);
  }

  allOptionsElements() {
    return Array.from(this.optionsTarget.querySelectorAll("li"));
  }

  selectedOptionIds() {
    return this.selectedTargets.map(t => t.dataset.id);
  }

  selectedOptionElements() {
    const options = this.allOptionsElements();
    const selectedOptionIds = this.selectedOptionIds();
    return options.filter(e => selectedOptionIds.includes(e.dataset.id));
  }

  allOtherOptionElements() {
    const options = this.allOptionsElements();
    const selectedOptionIds = this.selectedOptionIds();
    return options.filter(e => !selectedOptionIds.includes(e.dataset.id));
  }

  hideAllSelectedOptions() {
    const selectedOptions = this.selectedOptionElements();
    selectedOptions.forEach(e => e.classList.add("option-hidden"));
  }

  showAllOtherOptions() {
    const allOtherOptions = this.allOtherOptionElements();
    allOtherOptions.forEach(e => e.classList.remove("option-hidden"));
  }

  removePendingSelectedElements() {
    const elementsMarkedToRemove = this.selectedTargets.filter(t => t.dataset.toRemove);
    elementsMarkedToRemove.forEach(e => e.remove());
  }

  hideOptions(_event) {
    this.optionsTarget.classList.add("select_options_container-hidden");
  }

  filterOptions() {
    if (!this.hasSelectedTarget) {
      return;
    }
    this.removePendingSelectedElements();
    this.hideAllSelectedOptions();
    this.showAllOtherOptions();
  }

  showOptions(event) {
    event.stopPropagation();

    document.querySelectorAll(".ui.select .select_options_container").forEach(element => {
      element.classList.add("select_options_container-hidden");
    });

    this.filterOptions();
    this.optionsTarget.classList.remove("select_options_container-hidden");
  }

  async search(_event) {
    await this.stimulate(this.element.dataset.reflex, {
      query: this.queryTarget.value,
      element_id: this.optionsTarget.id,
      on_add: this.element.dataset.onAdd,
      controller: this.optionsTarget.getAttribute("data-custom-controller"),
    });
  }

  removeSelected(event) {
    event.stopPropagation();
    const { currentTarget } = event;
    const selectedElement = currentTarget.parentElement;
    const elementToRemove = this.selectedTargets.find(t => t.dataset.id == selectedElement.dataset.id);
    elementToRemove.setAttribute("data-to-remove", "true");
    this.filterOptions();
  }

  selectOption(event) {
    event.stopPropagation();
    const {
      target: { dataset },
    } = event;
    this.addSelectedLabelToControl(JSON.parse(dataset.value));
    this.queryTarget.value = "";
  }

  addSelectedLabelToControl(dataset) {
    const selected = this.createSelectedLabel(dataset);
    this.controlTarget.insertBefore(selected, this.queryTarget);
    this.filterOptions();
  }

  createSelectedLabel(dataset) {
    const selectedElement = document.createElement("div");
    selectedElement.classList.add("selected");
    selectedElement.setAttribute("data-multi-select-async-target", `selected ${this.element.dataset.selectedTarget}`);
    selectedElement.setAttribute("data-id", dataset.id);
    selectedElement.setAttribute("data-value", JSON.stringify(dataset));
    selectedElement.setAttribute("data-reflex-dataset", "combined");
    const elName = document.createElement("div");
    elName.classList.add("name");
    elName.appendChild(document.createTextNode(dataset.name));
    selectedElement.appendChild(elName);
    const elClose = document.createElement("div");
    elClose.classList.add("close");
    elClose.setAttribute("data-action", `${this.element.dataset.onRemove} click->multi-select-async#removeSelected`);
    const timesIcon = document.createElement("i");
    timesIcon.classList.add("fal", "fa-times");
    elClose.appendChild(timesIcon);
    selectedElement.appendChild(elClose);
    if (this.element.dataset.inputName) {
      const inputElement = this.createHiddenInput(dataset);
      selectedElement.appendChild(inputElement);
    }
    return selectedElement;
  }

  createHiddenInput(dataset) {
    const input = document.createElement("input");
    input.name = `${this.element.dataset.inputName}[${dataset.name}]`;
    input.type = "hidden";
    input.value = "1";
    return input;
  }
}
